import { Injectable } from '@angular/core';
import { DataTableConfig } from 'src/shared/models/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class CommonHelper {

  composeQuery(dataTableConfig: DataTableConfig) {
    let urlQuery = `pageIndex=${dataTableConfig.paginationConfig.pageIndex}&pageSize=${dataTableConfig.paginationConfig.pageSize}`;

    if (dataTableConfig.searchString) {
      urlQuery += `&searchString=${encodeURIComponent(
        dataTableConfig.searchString
      )}`;
    }
    else
    {
      urlQuery += `&searchString=`;
    }

    urlQuery += `&sortOrder=${
      dataTableConfig.sortOrder || 'asc'
    }&sortProperty=${dataTableConfig.sortProperty}`;

    return urlQuery;
  }
}
