import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../../../../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataTableConfig } from 'src/shared/models/pagination.model';
import { CommonHelper } from 'src/core/helpers/common-helper';
import { PaginatedLabelUsers } from '../models/user.model';
import { Observable, catchError, map, of } from 'rxjs';
import Jsona from 'jsona';
import { UserRole } from 'src/core/helpers/user-roles';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  headers = new HttpHeaders();
  headers2 = new HttpHeaders();

  protected managementServiceUrl = AppConfig.settings.apiServer.managementServiceUrl;
  protected authServiceUrl = AppConfig.settings.apiServer.authAPI;
  protected metafireServiceUrl = AppConfig.settings.apiServer.metafireSuiteAPI;
  protected apiServer = AppConfig.settings.apiServer;

  constructor(
    private httpClient: HttpClient,
    private commonHelper: CommonHelper
  ) {
    let token: any = JSON.parse(localStorage.getItem('currentUser')!)
      .authenticated.access_token;
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    this.headers2 = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      Authorization: 'Bearer ' + token,
    });
  }

  dataFormatter = new Jsona();

  //// This is the new api but it's not returning all the users correctly yet
  // getLabelUsers(dataTableConfig: DataTableConfig, boardId: string) {
  //   let urlQuery = "boardId=" + boardId + "&" + this.commonHelper.composeQuery(dataTableConfig);

  //   return this.httpClient.get<PaginatedLabelUsers>(
  //     this.managementServiceUrl + '/User/LabelUsers?' + urlQuery
  //   );
  // }

  getLabelUsers(boardId: string) {
    let options = { headers: this.headers };
    return this.httpClient.get(this.authServiceUrl + `/boards/${boardId}/users`, options).pipe(
      map((response: any) => {
        let res = this.dataFormatter.deserialize(response, {
          preferNestedDataFromData: true,
        });

        res.forEach((user: any) => {
          if (user.role.id == UserRole.Superadmin) user.role = 'Superadmin';
          else if (user.role.id == UserRole.LabelAdmin) user.role = 'Label Admin';
          else if (user.role.id == UserRole.LabelAgent) user.role = 'Label Agent';
          else if (user.role.id == UserRole.LabelUser) user.role = 'Label User';
          user.user.invitation = { isAccepted: true };
          if (user.user.hasImage) {
            user.user.imagePath = this.apiServer.awsS3URL + '/users/' + user.id + '/static/image-190x190.jpg';
          }
          Object.assign(user, user.user);
          delete user.user;
          user.isAccepted = user.invitation.isAccepted;
          delete user.invitation;
          user.sortName = user.firstName + ' ' + user.lastName;
        });

        return res;
      }
      )
    );
  }

  getInvitations(boardId: string) {
    let options = { headers: this.headers };
    return this.httpClient.get(this.authServiceUrl + `/invitations?boardId=${boardId}`, options).pipe(
      map((response: any) => {
        let res = this.dataFormatter.deserialize(response, {
          preferNestedDataFromData: true,
        });

        res = res.filter((x: any) => x.isAccepted == false);
        res.forEach((invitation: any) => {
          if (invitation.role.id == UserRole.Superadmin) invitation.role = 'Superadmin';
          else if (invitation.role.id == UserRole.LabelAdmin) invitation.role = 'Label Admin';
          else if (invitation.role.id == UserRole.LabelAgent) invitation.role = 'Label Agent';
          else if (invitation.role.id == UserRole.LabelUser) invitation.role = 'Label User';
          invitation.sortName = invitation.email;
        });

        return res;
      }
      )
    );
  }

  getRoles() {
    let options = { headers: this.headers };

    return this.httpClient.get(
      this.authServiceUrl + `/roles?productType=MetafireSuite`,
      options
    );
  }

  resendInvitation(invitationId: number, boardId: string) {
    let options = { headers: this.headers };
    let url = this.authServiceUrl + `/invitations/${invitationId}/resend?boardId=${boardId}`;
    return this.httpClient.get(url, options);
  }

  deleteUserFromAuth(boardId: string, userId: string){
    let options = { headers: this.headers };
    let url = this.authServiceUrl + `/boards/${boardId}/users/${userId}`;
    return this.httpClient.delete(url, options);   
  }

  deleteUser(boardId: string, userId: string) {
    const url = `${this.metafireServiceUrl}/api/board-users/${userId}?boardId=${boardId}`;
    return this.httpClient.delete(url);
  }

  inviteUser(email: string, boardId: string, roleId: any): Observable<any> {
    let options = { headers: this.headers2 };
    let body = {
      data: {
        attributes: {
          email: email,
          userType: 'BoardUser',
        },
        relationships: {
          board: {
            data: {
              id: boardId,
              type: 'boards',
            },
          },
          role: {
            data: {
              id: roleId,
              type: 'roles',
            },
          },
        },
        type: 'invitations',
      },
    };
    let url = this.authServiceUrl + `/invitations`;
    return this.httpClient.post(url, body, options);
  }




  /* New APIs  Start */
  getAllRoles(){
    let url = this.apiServer.managementServiceUrl + '/Role/GetRoles';
    return this.httpClient.get(url);
  }


  getLabelMembers(boardId: string){
    let url = this.apiServer.managementServiceUrl + '/User/GetLabelUsers?boardId=' + boardId;
    return this.httpClient.get(url);
  }


  deleteLabelMember(userId: string){
    let url = this.apiServer.managementServiceUrl + '/User/DeleteUser/' + userId;
    return this.httpClient.delete(url);
  }

  updateLabelMember(member: any, boardId: string){
    let url = this.apiServer.managementServiceUrl + '/User/UpdateUser';
    let bodyRequest = {
      userId: member.user.id,
      boardId: boardId,
      roleId: member.role.id,
      receiveFinancialStatements: member.user.receiveFinancialStatements,
      receiveNewReports: member.user.receiveNewReports
    }
    return this.httpClient.put(url, bodyRequest);
  }

  sendInvitation(email: string, boardId: string, roleId: string){
    let url = this.apiServer.managementServiceUrl + '/Invitation/CreateInvitation';
    let bodyRequest = {
      email: email,
      boardId: boardId,
      roleId: roleId
    }
    return this.httpClient.post(url, bodyRequest);
  }

  resendUserInvitation(invitationId: string){
    let url = this.apiServer.managementServiceUrl + '/Invitation/ResendInvitation?invitationId=' + invitationId;
    return this.httpClient.post(url, {});
  }

  deleteInvitation(invitationId: string){
    let url = this.apiServer.managementServiceUrl + '/Invitation/DeleteInvitation/' + invitationId;
    return this.httpClient.delete(url);
  }
  
  /* New APIs  End */
}
