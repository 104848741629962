import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { Company } from '../company-settings/shared/models/Company';
import { CompanyManagementService } from '../company-settings/shared/services/companyManagement.service';
import { UserRole } from 'src/core/helpers/user-roles';
import { SpinnerService } from 'src/core/services/spinner.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
  encapsulation: ViewEncapsulation.None // Temporarily set to None for testing

})
export class GeneralSettingsComponent {
  routerSubscription!: Subscription;
  companyData: Company = {};
  boardID!: string;

  isAdmin: boolean = false;
  currentUser = JSON.parse(localStorage.getItem('currentUser')!);
  currentUserRole = {
    id: this.currentUser.board.roles[0],
  };

  constructor(private companyService: CompanyManagementService,
    private route: ActivatedRoute,private spinnerService:SpinnerService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.routerSubscription = this.route.params.subscribe((params: Params) => {
      this.boardID = params['id'] || null;
    });
    this.loadData();
    this.isAdmin = UserRole.Superadmin == this.currentUserRole.id;
  }
  loadData(): void {
    this.spinnerService.show();
    this.companyService.getCompanybyBoardID(this.boardID).subscribe({
      next: (response) => {
        console.log(response);
        this.companyData = response;
        this.spinnerService.hide();
      },
      error: (error) => {        
        this.spinnerService.hide();
        this.messageService.add({
          severity: 'error',
          summary: 'Get Company',
          detail: 'Company Not Exist please Fill Company Data',
        });
      }
    });
  }
  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
