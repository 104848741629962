import { Component, Input, ViewChild } from '@angular/core';
import { Company } from '../company-settings/shared/models/Company';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { CompanyManagementService } from '../company-settings/shared/services/companyManagement.service';
import { MessageService } from 'primeng/api';
import { SpinnerService } from 'src/core/services/spinner.service';

@Component({
  selector: 'app-advanced',
  templateUrl: './advanced.component.html',
  styleUrls: ['./advanced.component.scss']
})
export class AdvancedComponent {
  private _companyDetails!: Company;
  toggleSwitchIdentifier:boolean=false;

@Input() set companyDetails(value: Company) {
  this._companyDetails = value;
  if (this._companyDetails) {
    //this.toggleSwitchIdentifier= (!this._companyDetails.identifier )?true:false;

    this.patchFormWithCompanyDetails();
  }
}
private patchFormWithCompanyDetails(): void {
  //console.log(this.toggleSwitch);
  this.companyForm.patchValue({
        identifier:this._companyDetails.identifier

   // identifier:(!this._companyDetails.identifier )?this._companyDetails.vatid:this._companyDetails.identifier
  });
}
companyForm: FormGroup;
animationState: string = '';


ngAfterViewInit() {
  if (this.companyForm) {
    this.companyForm.valueChanges.subscribe((_) => {
      if (this.companyForm.dirty) {
       // this.reportsFormChange = true;
        // remove slide-down from div of class unsaved-changes-alert
        this.animationState = 'slide-up';
      }
    });
  } else {
    console.error('ngForm is not available');
  }
}

constructor(private fb: FormBuilder,private companyService: CompanyManagementService,
  private messageService: MessageService,private spinnerService: SpinnerService

) {
  this.companyForm = this.fb.group({
    identifier: [''],
    boardId: ['']
    //toggleSwitch: [false] // Initialize the checkbox
  });
}



public cancel():void{
  this.animationState = 'slide-down';
    setTimeout(() => {
      // Hide the save changes alert
      //this.reportsFormChange = false;
      //this.getDistribution();
      // how to clear  newDistributionForm  dirty
      this.companyForm.markAsPristine();
      this.animationState = '';
    }, 200);
    this.patchFormWithCompanyDetails();
}
public UpdateCompany():void{
  this.spinnerService.show();
  //console.log(this._companyDetails.id);
  const company: Company = {
    ...this._companyDetails, // Preserve all existing properties
    identifier: this.companyForm.value.identifier, // Update only the modified property
  };

  this.companyService.UpdateCompany(company).subscribe({
    next: (response) => {
      console.log(response);
      this.spinnerService.hide();
      const identifierControl = this.companyForm.get('identifier');

if (this.toggleSwitchIdentifier) {
  identifierControl?.disable(); // Disable the control
} else {
  identifierControl?.enable(); // Enable the control
}
      this.messageService.add({
        severity: 'success',
        summary: 'Settings Saved',
        detail: 'Your changes have been Successfully Updated.',
      });
      //this.toggleSwitchIdentifier=!this.toggleSwitchIdentifier;
    },
    error: (error) => {
      //console.error('Error fetching data', error);
      this.spinnerService.hide();
      this.messageService.add({
        severity: 'error',
        summary: 'Update Identifier',
        detail: 'Please Try Again',
      });
    }
  });
  this.animationState = 'slide-down';
  setTimeout(() => {
    // Hide the save changes alert
    this.companyForm.markAsPristine();
    this.animationState = '';
  }, 200);
}
onToggleChange(event:Event){

  this.toggleSwitchIdentifier = !this.toggleSwitchIdentifier;
console.log(this.toggleSwitchIdentifier);
this.companyForm.patchValue({
  identifier:(this.toggleSwitchIdentifier )?this._companyDetails.vatid:this._companyDetails.identifier
});
this.UpdateCompany();

}
}
