import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { SpinnerService } from 'src/core/services/spinner.service';
import { Location } from '@angular/common';
import { UserRole,UserRoleNames } from 'src/core/helpers/user-roles';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { Boards } from 'src/core/helpers/boards';
import { UserService } from 'src/app/admin-tools/user-management/shared/services/user.service';

interface Member {
  user: {
    id: string;
    name: string;
    lastActive: string;
    hasImage: boolean;
    email: string;
    role: string;
    receiveFinancialStatements: boolean;
    receiveNewReports: boolean;
    invitationId: string;
  };
  role: {
    id: string;
    name: string;
  };
  board: {
    id: string
  }
}

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MembersComponent implements OnInit {
  boardId!: string;
  routerSubscription!: Subscription;
  currentUser: any;

  members: Member[] = [];

  roles: any[] = [];

  newMember = { email: '', role: '' };
  newMemberEmail = '';
  newMemberRole = '';
  emptyId='00000000-0000-0000-0000-000000000000';

  inviteForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    userRole: ['', Validators.required],
  });

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private userService: UserService,
    private spinnerService: SpinnerService,
    private location: Location,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    this.routerSubscription = this.route.params.subscribe((params: any) => {
      if (params.id) this.boardId = params.id;
      else {
        this.boardId = JSON.parse(
          localStorage.getItem('currentUser')!
        ).board.id;
        this.location.replaceState(
          window.location.pathname + '/' + this.boardId
        );
      }
    });

    this.getRoles();
    this.getLabelMembers();
  }

  getLabelMembers() {
    this.spinnerService.show();
    this.userService.getLabelMembers(this.boardId).subscribe((response: any) => {
      console.log(response);
      this.members = response.data;
      this.spinnerService.hide();
    });
  }

  otherActionMenu: MenuItem[] = [];
  showActionMenuToggle(member: any, event: MouseEvent, menu: any) {
    this.otherActionMenu = this.getMenuItems(member);
    menu.toggle(event);
  }

  getRoles() {
    this.spinnerService.show();
    this.userService.getAllRoles().subscribe((rolesResponse: any) => {
      if (this.boardId == Boards.MPO || this.boardId == Boards.MNN) {
        this.roles = rolesResponse.data.filter(
          (role: any) => role.name === UserRoleNames.Superadmin
        );
      } else {
        // check logged in user if Superadmin
        let userRole = JSON.parse(localStorage.getItem('currentUser')!).user
          .roleName;
        if (userRole === UserRoleNames.Superadmin) {
          this.roles = rolesResponse.data.filter(
            (role: any) => role.name !== UserRoleNames.Superadmin
          );
        } else {
          this.roles = rolesResponse.data.filter(
            (role: any) =>
              role.name !== UserRoleNames.Superadmin && role.name !== UserRoleNames.LabelAgent
          );
        }
      }
      // Sort roles by name in ascending order
      this.roles.sort((a, b) => a.name.localeCompare(b.name));
      this.spinnerService.hide();
    });
  }

  get UserRoleNames()
  {
    return UserRoleNames;
  }

  sendInvitation() {
    // Logic to send invitation
    let _email = this.inviteForm.getRawValue().email;
    let _userRole: any = this.inviteForm.getRawValue().userRole;

    this.userService
      .sendInvitation(_email!, this.boardId, _userRole!.id)
      .subscribe(
        (response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'New Invitation Sent',
            detail: `Invitation successfully sent to ${_email} as ${_userRole.name}.`,
            life: 3000,
          });

          this.inviteForm.reset();

          this.getLabelMembers();
        },
        (err: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Invitation Failed',
            detail: 'Invitation failed to send',
            life: 3000,
          });
        }
      );
  }

  getMenuItems(currentRow: any): MenuItem[] {
    return [
      { visible: false },
      {
        label: 'Re-invite member',
        command: () => this.resendInvitation(currentRow),
        visible:
          currentRow.user.id === this.emptyId &&
          !(
            currentRow.role.name === UserRoleNames.LabelAgent &&
            this.currentUser.user.roleName === UserRoleNames.LabelAdmin
          ),
      },
      {
        label: 'Delete invitation',
        command: () => this.deleteInvitation(currentRow),
        visible:
          currentRow.user.id === this.emptyId &&
          !(
            currentRow.role.name === UserRoleNames.LabelAgent &&
            this.currentUser.user.roleName === UserRoleNames.LabelAdmin
          ),
      },
      {
        label: 'Delete Member',
        command: () => this.deleteMember(currentRow),
        visible: currentRow.user.id !== this.emptyId,
      },
    ];
  }
  deleteInvitation(currentRow: any): void {
    this.userService.deleteInvitation(currentRow.user.invitationId).subscribe((res: any) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Delete Success',
        detail: 'Delete Success',
        life: 3000,
      });
      this.getLabelMembers();

    },
      (err: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Delete failed',
          detail: 'Delete failed',
          life: 3000,
        });
      });
  }
  deleteMember(currentRow: any): void {
    this.userService.deleteLabelMember(currentRow.user.id).subscribe(
      (res) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Delete Success',
          detail: 'Delete Success',
          life: 3000,
        });
        this.getLabelMembers();
      },
      (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Delete failed',
          detail: 'Delete failed',
          life: 3000,
        });
      }
    );
  }
  resendInvitation(currentRow: any): void {
    this.userService
      .resendUserInvitation(currentRow.user.invitationId)
      .subscribe(
        (res: any) => {
          // add success message
          this.messageService.add({
            severity: 'success',
            summary: 'Re-invitation sent',
            detail:
              'Re-invitation is sent successfully to ' + currentRow.user.email,
            life: 3000,
          });
        },
        (err) => {
          // add failed message
          this.messageService.add({
            severity: 'error',
            summary: 'Re-invitation failed',
            detail: 'Re-invitation failed to send',
            life: 3000,
          });
        }
      );
  }

  onMemberRoleChange(member: any, event: any) {
    this.updateMemberData(member, false);
  }

  onChangeSwitches(member: any, event: any, updateUser: boolean = false) {
    this.updateMemberData(member, updateUser);
  }

  updateMemberData(member: Member, updateUser: boolean) {
    this.userService.updateLabelMember(member, this.boardId).subscribe(
      (response: any) => {
        // add success message last for 3 seconds
        this.messageService.add({
          severity: 'success',
          summary: 'Member updated',
          detail: 'Member updated successfully',
          life: 3000,
        });
        if (updateUser) {
          this.authService.updateMetafireDUserEmail(
            member.user.id,
            member.user.email,
            member.user.name,
            member.board.id,
            member.user.receiveNewReports
          ).subscribe((res: any) => {
          });
        }

      },
      (err: any) => {
        // add failed message last for 3 seconds
        this.messageService.add({
          severity: 'error',
          summary: 'Member update',
          detail: 'Member update failed',
          life: 3000,
        });
      }
    );
  }

  disableUserRole(member: any): boolean {
    // check if the logged in user is SuperAdmin or not
    let userRole = JSON.parse(localStorage.getItem('currentUser')!).role;
    if (
      member.role.name === UserRoleNames.LabelAgent &&
      userRole === UserRole.Superadmin
    ) {
      return false;
    } else {
      return true;
    }
  }
}

