import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customPercentage'
})
export class CustomPercentagePipe implements PipeTransform {
  transform(value: number): string {
    // Check if the value has insignificant decimal places
    const roundedValue = Math.round(value * 1000000) / 1000000;
    const valueString = roundedValue.toFixed(6);

    // Check if the sixth digit after the decimal point is 1
    const sixthDigit = valueString.charAt(valueString.length - 1);
    if (sixthDigit === '1') {
      // Exclude the sixth digit
      const truncatedValue = Math.floor(roundedValue * 100000) / 100000;
      if (truncatedValue % 1 === 0) {
        // No significant decimal places
        return `${Math.round(truncatedValue)}`;
      } else {
        // With significant decimal places
        return `${truncatedValue.toFixed(5).replace(/\.?0+$/, '')}`;
      }
    } else {
      if (roundedValue % 1 === 0) {
        // No significant decimal places
        return `${Math.round(roundedValue)}`;
      } else {
        // With significant decimal places
        return `${roundedValue.toFixed(6).replace(/\.?0+$/, '')}`;
      }
    }
  }
}