import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { authGuard } from '../shared/guards/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { eligableGuard } from 'src/shared/guards/eligable.guard';
import { UserRole } from 'src/core/helpers/user-roles';
import { ReportsComponent } from './reports/report-list/reports.component';
import { NewLoginComponent } from './new-login/new-login.component';
import { NewSignupComponent } from './new-signup/new-signup.component';
import { AcceptInviteComponent } from './user/accept-invite/accept-invite.component';
import { OrganizationTitle } from 'src/core/helpers/organization-helper';
import { GeneralSettingsComponent } from './Settings/general-settings/general-settings.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [authGuard] },
  { path: 'labels/:boardId/invitation/:invitationId/user', component: NewSignupComponent },
  { path: 'labels/:boardId/accept-invite/:invitationId/:userId', component: AcceptInviteComponent },
  { path: 'login', component: NewLoginComponent, title: window.location.href.includes('no') ? OrganizationTitle.MusicNest : OrganizationTitle.MPO },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:id', component: ResetPasswordComponent },
  { path: 'settings/:id', component: GeneralSettingsComponent , canActivate: [authGuard, eligableGuard],
    data: { roles: [UserRole.Superadmin, UserRole.LabelAdmin, UserRole.LabelUser, UserRole.LabelAgent] }
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [authGuard],
  },
  { path: 'items', redirectTo: 'report-items', pathMatch: 'full' },
  {
    path: 'report-items',
    loadChildren: () => import('./report-items/reports-items.module').then((m) => m.ReportItemsModule),
    canActivate: [authGuard, eligableGuard],
    data: { roles: [UserRole.Superadmin, UserRole.LabelAdmin, UserRole.LabelUser, UserRole.LabelAgent] },
  },
  {
    path: 'disputes',
    loadChildren: () =>
      import('./disputes/disputes.module').then((m) => m.DisputesModule),
    canActivate: [authGuard],
  },
  {
    path: 'emailsender',
    loadChildren: () =>
      import('../app/admin-tools/email-sender/email-sender.module').then(
        (m) => m.EmailSenderModule
      ),
    canActivate: [authGuard, eligableGuard],
    data: { roles: [UserRole.Superadmin] },
  },
  {
    path: 'statistics',
    loadChildren: () =>
      import('./statistics/statistics.module').then((m) => m.StatisticsModule),
    canActivate: [authGuard, eligableGuard],
    data: { roles: [UserRole.Superadmin, UserRole.LabelAdmin, UserRole.LabelUser, UserRole.LabelAgent] },
  },
  {
    path: 'user-management',
    loadChildren: () =>
      import('../app/admin-tools/user-management/user-management.module').then(
        (m) => m.UserManagementModule
      ),
    canActivate: [authGuard, eligableGuard],
    data: { roles: [UserRole.Superadmin, UserRole.LabelAdmin] },
  },
  {
    path: 'label-management',
    loadChildren: () =>
      import(
        '../app/admin-tools/label-management/label-management.module'
      ).then((m) => m.LabelManagementModule),
    canActivate: [authGuard, eligableGuard],
    data: { roles: [UserRole.Superadmin, UserRole.LabelAdmin, UserRole.LabelUser, UserRole.LabelAgent] },
  },
  {
    path: 'alias-management',
    loadChildren: () =>
      import(
        '../app/admin-tools/alias-management/alias-management.module'
      ).then((m) => m.AliasManagementModule),
    canActivate: [authGuard, eligableGuard],
    data: { roles: [UserRole.Superadmin, UserRole.LabelAdmin] },
  },
  {
    path: 'profile-settings',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    canActivate: [authGuard],
  },
  {
    path: 'finance',
    loadChildren: () =>
      import('./finance/finance.module').then((m) => m.FinanceModule),
    canActivate: [authGuard],
  },
  {
    path: 'distributions',
    loadChildren: () =>
      import('./distributions/distributions.module').then((m) => m.DistributionModule),
    canActivate: [authGuard],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
