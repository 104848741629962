import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { OrganizationTitle } from 'src/core/helpers/organization-helper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  organization:string=OrganizationTitle.MPO;

  constructor(private router: Router) { 
    if (!localStorage.getItem("currentUser")) {
      this.router.navigate(['/login']);
    }
    else
    {
      this.router.navigate(['/reports']);
    }
    
  }

  ngInit() {
    this.organization = (sessionStorage.getItem('organisation') == 'no' ? OrganizationTitle.MusicNest: OrganizationTitle.MPO);
  }
}
