<div class="">
  <div>
    <p-panel styleClass="green-bar-panel">
      <ng-template pTemplate="body">
        <div class="panel-body">
          <p-table class="mpo-table-reports" [value]="members" [tableStyle]="{
            'min-width': '75rem'
          }">
            <ng-template pTemplate="header">
              <tr class="table-header">
                <th style="width: 20rem">Name</th>
                <th style="width: 10rem">Last Active</th>
                <th style="width: 14rem">User Role</th>
                <th>Financials statements</th>
                <th>New reports</th>
                <th class="table-action-col">Other actions</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-member>
              <tr>
                <td style="width: 20rem">
                  <div class="member-name">
                    {{
                    member.user.id !== emptyId
                    ? member.user.name
                    : "Invite sent"
                    }}
                  </div>
                  <div class="member-email">{{ member.user.email }}</div>
                </td>
                <td class="member-lastActive" style="width: 10rem">
                  {{
                  member.user.lastActive &&
                  member.user.id !== emptyId
                  ? (member.user.lastActive | date : "d. MMM. yyyy")
                  : "-"
                  }}
                </td>
                <td class="member-role-td" style="width: 14rem">
                  <p-dropdown *ngIf="
                      currentUser.user.roleName == UserRoleNames.Superadmin ||
                      (member.role.name !== UserRoleNames.LabelAgent &&
                        (currentUser.user.roleName == UserRoleNames.LabelAdmin ||
                          currentUser.user.roleName == UserRoleNames.LabelUser))
                    " class="member-role" [options]="roles" [(ngModel)]="member.role" optionLabel="name"
                    (onChange)="onMemberRoleChange(member, $event)" [disabled]="
                      member.user.id === emptyId
                    " optionDisabled="disabled"></p-dropdown>
                  <span *ngIf="
                      member.role.name === UserRoleNames.LabelAgent &&
                      currentUser.user.roleName !== UserRoleNames.Superadmin
                    ">{{ member.role.name }}</span>
                </td>
                <td>
                  <p-inputSwitch [(ngModel)]="member.user.receiveFinancialStatements"
                    (onChange)="onChangeSwitches(member, $event)" [disabled]="
                      member.user.id === emptyId
                    " />
                </td>
                <td>
                  <p-inputSwitch [(ngModel)]="member.user.receiveNewReports"
                    (onChange)="onChangeSwitches(member, $event, true)" [disabled]="
                      member.user.id === emptyId
                    " />
                </td>
                <td class="table-action-col">
                  <p-menu #menu [model]="otherActionMenu" [popup]="true" appendTo="body"></p-menu>
                  <i class="pi pi-ellipsis-v" (click)="showActionMenuToggle(member, $event, menu)"></i>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8">
                  <p class="empty-records">
                    No users is connected to this label
                  </p>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <p-divider />
          <div class="invite-member">
            <h6 class="custom-header">Invite new member</h6>
            <br>
            <form [formGroup]="inviteForm">
              <div class="row">
                <div class="invite-user-email-input-div">
                  <label class="invite-user-label" for="email">E-mail for new member</label>
                  <input pInputText type="email" id="email" placeholder="Type in E-mail" formControlName="email"
                    style="width: 100%" />
                  <div *ngIf="
                      inviteForm.controls.email.invalid &&
                      inviteForm.controls.email.touched
                    ">
                    <small class="text-danger" *ngIf="inviteForm.controls.email.errors!.required">Email is
                      required.</small>
                    <small class="text-danger" *ngIf="inviteForm.controls.email.errors!.email">Please enter a valid
                      email.</small>
                  </div>
                </div>
                <div class="invite-user-role-div">
                  <label class="invite-user-label" for="roles">Choose type of member</label>
                  <p-dropdown id="roles" [options]="roles" formControlName="userRole" optionLabel="name"></p-dropdown>
                </div>
                <div class="invite-member-submit">
                  <p-button class="invite-user-submit" label="Send invitation" type="submit"
                    styleClass="p-button-success " (click)="sendInvitation()"
                    [disabled]="inviteForm.invalid"></p-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ng-template>
    </p-panel>
  </div>
</div>


