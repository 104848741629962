import { Component, Input, OnInit } from '@angular/core';
import { CompanyManagementService } from './shared/services/companyManagement.service';
import { Company } from './shared/models/Company';
import { Country } from './shared/models/Country';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject, Subscription, catchError, finalize, takeUntil, throwError } from 'rxjs';
import { SpinnerService } from 'src/core/services/spinner.service';
import { MessageService } from 'primeng/api';
import { Label } from 'src/app/admin-tools/label-management/shared/models/label.model';
import { LabelService } from 'src/app/admin-tools/label-management/shared/services/label.service';


@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss']
})

export class CompanySettingsComponent implements OnInit {
  companyData: Company = {}; 
  currentUser: any;
  countryCode:string='';
  countryEU:boolean=true;
  DisconnectVatID:boolean=false;
  routerSubscription!: Subscription;
  newCompany: boolean=true;
  ValidVatID: boolean=true;
  animationState: string = '';
  countries:Country[]=[];
  companyForm: FormGroup;
  Denmark: boolean = false ;
  Norway: boolean = false ;
  _isAdmin: boolean=false; 
  hasMembership: boolean=false; 
  editLabelSubscription!: Subscription;


  public _companyDetails!: Company; 

@Input() set isAdmin(value: boolean){
  this._isAdmin = value;
  const brandNameControl = this.companyForm.get('brandName');

  if (!this._isAdmin) 
    brandNameControl?.disable(); // Disable the control
  else 
    brandNameControl?.enable(); // Enable the control
}

@Input() set companyDetails(value: Company) {
  this._companyDetails = value;
  if (Object.keys(this._companyDetails).length != 0 && this._companyDetails.isFullyCreated==true ) {
    this.newCompany = false;
    this.patchFormWithCompanyDetails();
    this.Denmark = this._companyDetails.registeredCountry =='Denmark'?true: false;
    this.Norway = this._companyDetails.registeredCountry =='Norway'?true: false;
    this.hasMembership = (this._companyDetails.iFPIDanmark||this._companyDetails.iFPINorge||this._companyDetails.fONO)?true:false
  
  }
  else
  {
    const companyTypeControl = this.companyForm.get('companyType');
    if (companyTypeControl) 
    companyTypeControl.setValue('Business');
    this.spinnerService.hide();
    this.newCompany = true;
  }
}


  constructor(private companyService: CompanyManagementService,
    private fb: FormBuilder,private route: ActivatedRoute,
    private spinnerService:SpinnerService,
    private messageService: MessageService,
    private labelService: LabelService) {
    this.companyForm = this.fb.group({
      companyType: ['', Validators.required ],
      registeredCountry: ['', Validators.required],
      vatid: ['',Validators.required ], 
      brandName: [''], 
      legalName: [''], 
      city: [''],
      zip: [''], 
      address: [''], 
      boardId: ['']
    });
  }

  ngOnInit(): void {
    this.getCountries();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')!);
  }

  private patchFormWithCompanyDetails(): void {
    this.companyForm.patchValue(this._companyDetails);
  }

  getCountries()
  {
  this.companyService.getCountries().subscribe(
    data => {
      this.countries = data; // Assign the retrieved data to the countries array
    },
    error => {
      //console.error('Error fetching countries:', error); // Handle any errors
    }
  );
}

  onSubmit() {
    if (this.companyForm.valid) {
      const formValue = this.companyForm.value;   
    }
  }

  validateVat()
  {
    this.spinnerService.show();
    this.countryCode=this.countries.find(x=>x.name==this.companyForm.value.registeredCountry)?.code || '';
    this.countryEU =this.countries.find(x=>x.name==this.companyForm.value.registeredCountry)?.isEU || false;
    this.companyService.ValidateVatID(this.countryCode,this.countryEU,this.companyForm.value.vatid,this.companyForm.value.boardId).subscribe({
      next: (response) => {      
        if(response.isValid)
        {
          this.companyForm.patchValue(response);
          this.companyForm.disable();
          this.toggleDisconnectVatID();
          this.ValidVatID=true;
          this.messageService.add({
            severity: 'success',
            summary: 'Validated VatID',
            detail: 'Valid VatID',
          });
          this.UpdateCompany();
        }
        else{
          this.ValidVatID=false;
          this.companyForm.enable();
        }
        this.spinnerService.hide();
      },
      error: (error) => {
        //console.error('Error fetching data', error);
        this.spinnerService.hide();
        this.messageService.add({
          severity: 'error',
          summary: 'Validate VatID Error',
          detail: 'Please Try Again',
        });
      }
    });
  }

  UpdateCompany()
  {
    const company: Company = {
      ...this._companyDetails,
      legalName : this.companyForm.value.legalName,
      brandName : this.companyForm.value.brandName,
      vatid : this.companyForm.value.vatid,
      city : this.companyForm.value.city,
      zip : this.companyForm.value.zip,
      address : this.companyForm.value.address 
    };
    this.companyService.UpdateCompany(company).subscribe({
      next: (response) => {
        if(this.companyForm.value.brandName !== this._companyDetails.brandName)
          {
            this.editLabel();
          }
       this.messageService.add({
        severity: 'success',
        summary: 'Settings Saved',
        detail: 'Your changes have been Successfully Updated.',
      });
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Settings Saved Error',
          detail: 'Please Try Again.',
        });
      }
    });
    this.animationState = 'slide-down';
    setTimeout(() => {
      this.companyForm.markAsPristine();
      this.animationState = '';
    }, 200);
  }

  toggleDisconnectVatID() {
    this.DisconnectVatID = !this.DisconnectVatID;
    if (this.DisconnectVatID) {
      this.companyForm.disable(); // Disable the form if DisconnectVatID is true
    } else {
      this.companyForm.enable(); // Enable the form if DisconnectVatID is false
    }
  }

  public cancel():void{
    this.animationState = 'slide-down';
      setTimeout(() => {
        this.companyForm.markAsPristine();
        this.animationState = '';
      }, 200);
      this.patchFormWithCompanyDetails();
  }

  editLabel() {
    let labelData: Label = {      
      "id": this._companyDetails.boardId,  
      "name": this.companyForm.value.brandName,
      "hasImage":false, 
      "isCollectingForItself":this._companyDetails.isCollectingForItself??false,    
      "parentBoardId": this._companyDetails.parentBoardId??this.currentUser.board.id
    };
    this.editLabelSubscription = this.labelService.editLabel(labelData).pipe(
      catchError((error) => {
     
        return throwError(() => {
          //console.log(error);
        }); // rethrow the error after handling
      })
    ).subscribe(

    );
  }


}