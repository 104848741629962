<div class="card companyCoard">
    <div class="card-body">
      <form [formGroup]="companyForm" (ngSubmit)="onSubmit()">
   
<div class="row">
    <section class="fillvatid">
      <h4 class="headerPageCustom">Recommended: Fill by VAT ID</h4>
      <p class="paragraphCustom">
        Please enter only the numbers of your VAT ID, without the
        country code or any initial letters.
      </p>
      <div class="row">
        <div class="col-2">
            <label class="labelCompany">
                Type
            </label>
            <select class="form-select form-select-lg selectCompanyCustom mt-1" id="inputGroupSelect03" formControlName="companyType" required>
                <option value="Business">Business</option>
                <option value="Personal">Personal</option>               
            </select>
        </div>
        <div class="col-2" style="padding-left: 0px;">
            <label class="labelCompany">
                Registered Country
            </label>
            <select class="form-select form-select-lg selectCompanyCustom mt-1" id="inputGroupSelect" formControlName="registeredCountry" required>                  
                <option *ngFor="let country of countries" 
                [value]="country.name" [attr.data-iseu]="country.isEU" [attr.data-code]="country.code">
                  {{ country.name }}
                </option>                      
            </select>
        </div>
        <div class="col-8 input searchinput" style="padding-left: 0px;">
            <label class="labelCompany">
                VAT ID / CVR / ORGANISATIONSNUMMER
            </label>
            
              <span class="searchwrap">
                <input type="text" class="form-control form-control-lg" style="min-height: 60px;"  formControlName="vatid" required>
                <!-- <button class="btn green">Validate and add VAT</button> -->
                <button *ngIf="DisconnectVatID" class="btn red" (click)="toggleDisconnectVatID()" >Disconnect VAT ID</button>
                <button *ngIf="!DisconnectVatID" class="btn green" 
                [disabled]="companyForm.get('vatid')?.invalid || companyForm.get('vatid')?.value === ''
                || !companyForm.get('registeredCountry')?.value"
                (click)="companyForm.get('vatid'
                  )?.valid ? validateVat() : null">Validate VAT ID</button>

            </span>
            <span style="color: #D24444;" *ngIf="!ValidVatID">
              <i class="pi pi-times-circle" style="font-size: 1.3rem;color:#D24444;"></i>
              
              <label class="labelCompany" style="color: #D24444;" ><b>No,</b>invalid VAT number</label>
               
            </span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
        <button type="button" style="width: 100%;" class="btn white" *ngIf="newCompany" (click)="newCompany=false">Or add manually</button>
      </div>
    </div>
    </section>
  </div>
<hr *ngIf="!newCompany">

<section class="nameofcompany" *ngIf="!newCompany">
  <h4 class="headerPageCustom">Name of company</h4>
  <p class="paragraphCustom">
    The brand name serves as a simplified representation of your
    company across the platform, newsletters, and other forms of
    communication, while the legal identity is used for financial
    transactions and official matters..
  </p>
  <div class="row mt-1">
    <div class="col-6">
      <label class="labelCompany">
        Brand name (used across the platform)
      </label>
    <input tyoe="text"  class="form-control form-control-lg" style="min-height: 60px;font-size: 16px;text-shadow: 0 0 0 2px black" formControlName="brandName" >
    </div>
    <div class="col-6">
      <label class="labelCompany">
        Legal Name of Company    </label>
    <input tyoe="text" class="form-control form-control-lg"style="min-height: 60px;font-size: 16px;;text-shadow: 0 0 0 2px black"  formControlName="legalName" >
    </div>


  </div>
  </section>
  <hr *ngIf="!newCompany">
  <section class="addressinformation" *ngIf="!newCompany">
    <h4 class="headerPageCustom">Address information</h4>
    <!-- <p class="paragraphCustom">
      Bla bla bla...
    </p> -->
    <div class="row mt-1">
      <div class="col-2">
        <label class="labelCompany">Registered Country</label>
        <select class="form-select form-select-lg " id="inputGroupSelectCou" formControlName="registeredCountry" required>                  
          <option *ngFor="let country of countries" 
          [value]="country.name" [attr.data-iseu]="country.isEU">
            {{ country.name }}
          </option>                      
      </select>
      </div>
      <div class="col-2">
        <label class="labelCompany">City</label>
        <input tyoe="text" class="form-control" formControlName="city" >
      </div>
      <div class="col-2">
        <label class="labelCompany">ZIP</label>
        <input tyoe="text" class="form-control" formControlName="zip" >
      </div>
      <div class="col-6">
        <label class="labelCompany">Address</label>
        <input tyoe="text" class="form-control" formControlName="address" >
      </div>
      </div>
      </section>
    </form>
      <hr *ngIf="hasMembership&& !newCompany">
      <section class="memberships" *ngIf="hasMembership && !newCompany">
        <h4 class="headerPageCustom">Memberships</h4>
        <p class="paragraphCustom">
          Please reach out to MPO/Music Nest if your label is incorrectly linked to any of the following
          organizations:
        </p>
  
        <article>
          <div class="CompanyMembershipCompany" *ngIf="_companyDetails.iFPIDanmark" >
            <span><img src="../assets/images/ifpidenmark.svg"  ></span>
            <p>IFPI Danmark</p>
          </div>
          <div class="CompanyMembershipCompany" *ngIf="_companyDetails.iFPINorge" >
            <span><img src="../assets/images/ifpinorway-favicon.png"  ></span>
            <p>IFPI Norge</p>
          </div>
          <div class="CompanyMembershipCompany" *ngIf="_companyDetails.fONO" >
            <span><img src="../assets/images/fono-favicon.png"  ></span>
            <p>FONO</p>
          </div>
        </article>



        </section>
        <hr *ngIf="!newCompany">
        <section class="Paymentdetails" *ngIf="!newCompany">
          <h4 class="headerPageCustom">Payment details </h4>
          <p class="paragraphCustom">
            The current information which is used for distributions. To change your account please contact MPO/MusicNest
          </p>
          <div class="row mt-4 pb-4" *ngIf="_companyDetails.isCollectingForItself && Denmark &&_companyDetails.useNemkonto">
            <div  class="alert alert-secondary" role="alert">
             Danish Companies will receive payments through <b>NemKonto</b>
             Any Changes to this require prior approval from MPO
            </div>
          </div>
        <div class="row mt-4 pb-4" *ngIf="_companyDetails.isCollectingForItself && Denmark &&!_companyDetails.useNemkonto">
            <div class=" col-6 input">
              <label class="labelCompany">Registration Number
                <img src="../assets/images/lock.svg" alt="Locked field" />
              </label>
              
              <input disabled type="text" class="form-control" [value]="_companyDetails.dK_RegNo" />
            </div>
            <div class="col-6 input">
              <label class="labelCompany">Account Number
                <img src="../assets/images/lock.svg" alt="Locked field" /></label>
              
              <input disabled type="text"  class="form-control" [value]="_companyDetails.dK_AccountNo" />
              <!-- <div class="validation">Please write a correct mail</div> -->

            </div>
          </div>
          <div class="row mt-4 pb-4" *ngIf="_companyDetails.isCollectingForItself && Norway">
            <div class="col-12 ">
              <label class="labelCompany">Account Number
                <img src="../assets/images/lock.svg" alt="Locked field" /></label>
              
              <input type="text" class="form-control" [value]="_companyDetails.nO_AccountNo">
              <!-- <div class="validation">Please write a correct mail</div> -->

            </div>
          </div>
          <div class="row mt-4 pb-4" *ngIf="_companyDetails.isCollectingForItself && !Norway && !Denmark">
            <div class="col-4 ">
              <label class="labelCompany">Name of Bank
                <img src="../assets/images/lock.svg" alt="Locked field" />
              </label>
             
              <input  disabled type="text" class="form-control" [value]="_companyDetails.Intl_NameOfBank" />            
            </div>
            <div class="col-4 ">
                <label class="labelCompany">IBAN
                  <img src="../assets/images/lock.svg" alt="Locked field" />
                </label>
                
                <input disabled type="text" class="form-control" [value]="_companyDetails.Intl_IBAN"/>            
              </div>
              <div class="col-4 ">
                <label class="labelCompany">Swift/BIC
                   <img src="../assets/images/lock.svg" alt="Locked field" /></label>
               
                <input disabled type="text" class="form-control" [value]="_companyDetails.Intl_Swicfbic" />            
              </div>
          </div>
          <div class="row mt-4 pb-4" *ngIf="!_companyDetails.isCollectingForItself ">
            <div  class="alert alert-secondary" role="alert">
             Remuneration will be Paid Directly to <b>&#123;Name of collecting Label&#125;</b>
          </div>
          </div>

          
          <!-- <div class="row"> -->
            
            <!-- <div class="col-6">
              <label class="labelCompany">
                Registration Number<img src="../assets/images/lock.svg" alt="Locked field" />
              </label>
            <input tyoe="text" disabled class="form-control">
            </div>
            <div class="col-6">
              <label class="labelCompany">
                
Account Number<img src="../assets/images/lock.svg" alt="Locked field" /> </label>
            <input tyoe="text" class="form-control" disabled>
            </div> -->
          <!-- </div> -->
          </section>   
          <!-- <div class=" row m-2">
            <div class="col-2 ml-5">
            <button style="background-color: #018083;color: white;" class="btn " (click)="UpdateCompany()">Update</button>
          </div>
          </div>     -->
     
</div>
</div>
<div
  class="unsaved-changes-alert"
  [ngClass]="animationState"
  *ngIf="companyForm.dirty "
>
  <p class="fw-bold mb-0">You have unsaved changes!</p>
  <button pButton class="discardbtn" (click)="cancel()">Discard changes</button>
  <button pButton class="savebtn" (click)="UpdateCompany()">
    Save changes
  </button>
</div>