import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthenticationService } from '../core/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment.production';
import { RefreshTokenService } from 'src/core/services/refresh-token.service';
import { ThemeService } from './theme.service';
import { Title } from '@angular/platform-browser';
import { OrganizationTitle } from 'src/core/helpers/organization-helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = '';
  currentUser: any = null;
  items: MenuItem[] | undefined;
  userMenuItems: MenuItem[] | undefined;

  themes = [
    {
      id: 'dk',
      label: 'Denmark theme',
    },
    {
      id: 'no',
      label: 'Norway theme',
    },
  ];

  constructor(
    public authService: AuthenticationService,
    private translate: TranslateService,
    private refreshTokenService: RefreshTokenService,
    private themeService: ThemeService,
    private titleService: Title
  ) {
    this.currentUser = localStorage.getItem('currentUser');
    this.translate.setDefaultLang(environment.language1);
    this.translate.use(environment.language1);

    const currentUrl = window.location.href; 
     if (currentUrl.includes('musicnest.no')) {
      this.setNOTheme();
    } else {  
        this.setDKTheme(); // default theme     
    }
  }

  setDKTheme(){
    sessionStorage.setItem('organisation', 'dk');
    this.titleService.setTitle(OrganizationTitle.MPO);    
    this.themeService.switchTheme(this.themes[0].id);
  }

  setNOTheme(){
    sessionStorage.setItem('organisation', 'no');
    this.titleService.setTitle(OrganizationTitle.MusicNest);    
    this.themeService.switchTheme(this.themes[1].id);
  }

  ngOnInit() {
    let currentLoggedUser = JSON.parse(localStorage.getItem('currentUser')!)
    if (currentLoggedUser != null && currentLoggedUser.authenticated) {
      this.authService.loggedIn.next(true);
      // Start the API calls using the service
      this.refreshTokenService.setApiInterval();
    } else {
      this.authService.loggedIn.next(false);
    }
  }

  

}
