<div class="d-flex flex-column justify-content-center mpologin align-items-center gap-5">
  <img src="assets/images/{{organizationPrefix}}/logo/logo.svg" width="100px" />

  <div class="main d-flex">
    <div class="left">
      <img [src]="image['Image link']" />
      <span class="metadata">
        <p class="fw-bold mb-1">{{image['Artist']}}</p>
        <p>{{image['Country']}}</p>
      </span>
    </div>
    <div class="d-flex flex-column justify-content-between flex-fill p-5 bg-white border rounded-end-1">
      <div class="d-flex flex-column">
        <h1 class="fw-bold mb-0">Create account</h1>
        <p class=" mt-1">as <span class="fw-bold">{{userRole}}</span> connected to <span class="fw-bold">{{board}}</span>.</p>
      <hr class="mpodashed">
      </div>
      <form class="d-flex flex-column justify-content-between flex-fill" (submit)="createUser()" [formGroup]="userData">
        <div>
          <p class="mb-0">Secure, one-time codes are utilized for password-free login,</p>
          <p class="mb-1">eliminating the need to create a password.</p>
        </div>
        <div class="d-flex flex-column gap-1 mt-3">
          <label htmlFor="email" class="fw-bold">E-mail</label>
          <input pInputText id="email" formControlName="email" />
        </div>
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex flex-column gap-1 w-50 me-3 mt-3">
            <label htmlFor="firstName" class="fw-bold">First name</label>
            <input pInputText id="firstName" formControlName="firstName" />
            <small *ngIf="userData.controls.firstName.dirty && userData.controls.firstName.invalid" id="firstName-help"
              class="p-error">
              must contain characters only
            </small>
          </div>
          <div class="d-flex flex-column gap-1 w-50 ms-3 mt-3">
            <label htmlFor="lastName" class="fw-bold">Last name</label>
            <input pInputText id="lastName" formControlName="lastName" />
            <small *ngIf="userData.controls.lastName.dirty && userData.controls.lastName.invalid" id="lastName-help"
              class="p-error">
              must contain characters only
            </small>
          </div>
        </div>
        <div class="d-flex flex-column gap-1 mt-3">
          <label htmlFor="jobTitle" class="fw-bold">Job title</label>
          <input pInputText id="jobTitle" formControlName="position" />
        </div>
        <div class="d-flex flex-column gap-1 mt-3">
          <label htmlFor="phoneNumber" class="fw-bold">Phone number</label>
          <input pInputText id="phoneNumber" placeholder="Don't forget to include the country code..."
            formControlName="phoneNumber" />
          <small *ngIf="userData.controls.phoneNumber.dirty && userData.controls.phoneNumber.invalid"
            id="phoneNumber-help" class="p-error">
            must contain numbers only
          </small>
        </div>
        <p-button type="submit" [loading]="createAccountLoading" [disabled]="userData.invalid" label="Create account and log in"
          styleClass="p-button-success w-100 mt-3"></p-button>
      </form>
    </div>
  </div>
</div>