import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { Company } from 'src/app/Settings/company-settings/shared/models/Company';
import { ValidateVatResponse } from '../models/ValidateVatResponse';
import { BaseResponse } from 'src/shared/models/response.model';
import { Observable } from 'rxjs';
import { Country } from '../models/Country';
import { Label } from 'src/app/admin-tools/label-management/shared/models/label.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyManagementService {
  protected ManagementServiceUrl = AppConfig.settings.apiServer.managementServiceUrl;

  constructor(private http: HttpClient)
  { }

  getCompanybyBoardID(boardID: string): Observable<any> {
    const url = `${this.ManagementServiceUrl}/company/GetCompanybyBoardID?BoardID=${boardID}`;
    return this.http.get<any>(url).pipe(
      map(response => {   
        console.log(response.data)    
        return {
          ...response.data, // Spread the existing fields
          iFPIDanmark: response.data.ifpiDanmark , // Update or provide default value
          iFPINorge: response.data.ifpiNorge , // Update or provide default value
          fONO: response.data.fono  // Update or provide default value
        } as Company;
      })
    );
  }

  getCountries(): Observable<any> {
    const url = `${this.ManagementServiceUrl}/Country/GetCountries`;
    return this.http.get<any>(url).pipe(
      map(response => {     
        return response.data ;
      })
    );
  }



  ValidateVatID(CountryCode: string, IsEU: boolean, VatNumber: string, BoardId: string): Observable<Company> {
   // const url = `${this.ManagementServiceUrl}/ValidateVatID?CountryCode=${CountryCode},IsEU=${IsEU},VatNumber=${VatNumber},BoardId=${BoardId}`;
    const url = `${this.ManagementServiceUrl}/Company/ValidateVatID?CountryCode=${String(CountryCode)}&IsEU=${IsEU}&VatNumber=${String(VatNumber)}&BoardId=${String(BoardId)}`;
    return this.http.get<any>(url).pipe(
      map(response => {
   
       const name= (response.data.companyName)?response.data.companyName.replace(/\s+/g, ' ').trim():'';
        const company: Company = {        
          isValid: response.data.isValid,
          legalName:name ,
          address: response.data.companyAddress,       
        };
        return company;
      })
    );
  }

  UpdateCompany(company:Company) {
    const url = `${this.ManagementServiceUrl}/company/UpdateCompany`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify(company);
    return this.http.put<any>(url, body, { headers }).pipe(
      map(response => {
        console.log(response);
        return response ;
      })
    );
  }

  CreateCompany(company:Company) {
    const url = `${this.ManagementServiceUrl}/company/CreateCompany`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify(company);
    return this.http.post<any>(url, body, { headers }).pipe(
      map(response => {
        //console.log(response);
        return response ;
      })
    );
  }

  GetParentBoards(Organization:string , userBoardID:string): Observable<any> {
    const url = `${this.ManagementServiceUrl}/Board/GetParentBoards?Organization=${String(Organization)}&BoardID=${String(userBoardID)}`;
    return this.http.get<any>(url).pipe(
     map(response => {  
       console.log(response + "   da eiiiih ")
        return response.data ;
      })
    );
  }
}