import { SpinnerService } from 'src/core/services/spinner.service';
import es from '@angular/common/locales/es';
import { FileUpload } from 'primeng/fileupload';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  Form,
  FormControl,
  FormGroup,
  FormsModule,
  NgForm,
  Validators,
} from '@angular/forms';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { Reporter } from 'src/app/reports/shared/models/reporter.model';
import { DistributionService } from '../shared/services/distributions.service';
import {
  ActivatedRoute,
  ResolveEnd,
  ResolveStart,
  Router,
} from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { registerLocaleData } from '@angular/common';
import { FinanceService } from 'src/app/finance/shared/services/finance.service';

interface DistributionReportItem {
  id?: string;
  reporter: any;
  name: string;
  year: number;
  splitPercentage: number;
  effectiveVatRate: number;
  tracks: number;
  assigned: number;
  unassigned: number;
  streamsDuration: number;
  financialValue: number;
  action: string;
  uploadLoading: boolean;
}

interface NewDistributionFormData {
  DistributionTitle: FormControl<string | null>;
  DistributionYear: FormControl<number | null>;
  DistributionID: FormControl<string | null>;
  DistributionDescription: FormControl<string | null>;
  DistributionUsages: FormControl<string[] | null>;
  receivedFunds: FormControl<number | null>;
  unknownHoldersReservation: FormControl<number | null>;
  includeVAT: FormControl<boolean | null>;
  isAdministrationFeesIncluded: FormControl<boolean | null>;
  administrationFeesAmount?: FormControl<number | null>;
  totalCostOfAdministrationInCurrentYear?: FormControl<number | null>;
  totalCostOfAdministrationToOffset?: FormControl<number | null>;
  isDistributeOnlyOnRemuneratedTracksActive: FormControl<boolean | null>;
  reservationType: FormControl<any | null>;
  isUploadOnAccountActive: FormControl<boolean | null>;
  isUploadWithHoldingActive: FormControl<boolean | null>;
  VATStatementType: FormControl<string | null>;
  royaltyType: FormControl<string | null>;
  VATStatement: FormControl<string | null>;
  RecievedFundsVATAmount: FormControl<number | null>;
  royalty: FormControl<string | null>;
  FirstInvoiceNumber : FormControl<string | null>;
  InvoiceSuffix : FormControl<string | null>;
  LastUsedInvoiceNumber : FormControl<string | null>;
}

@Component({
  selector: 'app-create-distribution',
  templateUrl: './create-distribution.component.html',
  styleUrls: ['./create-distribution.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class CreateDistributionComponent implements OnInit {
  newDistributionForm!: FormGroup<NewDistributionFormData>;
  reporters: Reporter[] = [];
  organizationPrefix: string = 'dk';
  distributionId!: string;
  nonMatchedOnAccountLabels : any[] = [];
  nonMatchedWithholdingLabels : any[] = []; 
  nonMatchedOnAccountLabelsExist = false;
  nonMatchedWithholdingLabelsExist = false;
  nonMatcheReportLabels : any [] = [];
  nonMatchedRepoortLabelsExist = false;
  @Input() data: DistributionReportItem[] = [];

  reportsSummary = {
    numberOfTracks: 0,
    labelsCount: 0,
    collectorsCount: 0,
    unclaimedRepertoireCount: 0,
    unclaimedRepertoireStreamsDuration: 0,
    unclaimedRepertoireValue: 0,
    totalReportAmount: 0,
    totalAmountForTransfer: 0,
  };

  cols: any[] = [
    { field: '', header: '', width: '1%' },
    { field: 'reporter', header: 'Report Icon', width: '5%' },
    { field: 'name', header: 'Name', width: '20%' },
    { field: 'year', header: 'Year', width: '6%' },
    { field: 'splitPercentage', header: 'Split (%)', width: '7%' },
    { field: 'tracks', header: 'Tracks', width: '9%' },
    { field: 'assigned', header: 'Assigned', width: '8%' },
    { field: 'unassigned', header: 'Unassigned & disputed', width: '8%' },
    { field: 'streamsDuration', header: 'Streams/Duration', width: '12%' },
    { field: 'financialValue', header: 'Financial value', width: '12%' },
    { field: 'action', header: '', width: '10%' },
    { field: 'action', header: '', width: '2%' },
  ];

  totalOnAccount = 0;
  totalWithHolding = 0;
  isEditMode: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private distributionService: DistributionService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private SpinnerService: SpinnerService,
    private financeService: FinanceService
  ) {}

  reportsFormChange = false;
  animationState: string = '';
  @ViewChild('ngForm') ngForm!: NgForm;

  ngAfterViewInit() {
    if (this.ngForm) {
      this.ngForm.form.valueChanges.subscribe((_) => {
        if (this.ngForm.form.dirty) {
          this.reportsFormChange = true;
          // remove slide-down from div of class unsaved-changes-alert
          this.animationState = 'slide-up';
        }
      });
    } else {
      console.error('ngForm is not available');
    }
  }

  ngOnInit() {
    this.router.events.subscribe((ev) => {
      if (ev instanceof ResolveStart) this.SpinnerService.show();
      if (ev instanceof ResolveEnd) this.SpinnerService.hide();
    });

    registerLocaleData(es);
    this.getOrganizationPrefix();
    this.initForms();

    this.activatedRoute.data.subscribe((response: any) => {
      this.reporters = response.reporters;
      this.setDistributionData(response.distribution);
    });
    // // get id from url
    this.distributionId = this.route.snapshot.params.id;
    this.isEditMode = this.distributionService.getEditMode();
  }

  distributionData: any;

  getDistribution() {
    this.distributionService
      .getDistribution(this.route.snapshot.params.id)
      .subscribe((res: any) => {
        this.setDistributionData(res);
        this.newDistributionForm.markAsPristine();
      });
  }

  setDistributionData(res: any) {
    this.distributionData = res;
    this.data = [];
    this.totalOnAccount = res.recognizedLabelsOnAccount;
    this.totalWithHolding = res.recognizedLabelsWithHolding;
    this.newDistributionForm.controls.DistributionTitle.setValue(res.title);
    this.newDistributionForm.controls.DistributionYear.setValue(
      res.distributionYear
    );
    this.newDistributionForm.controls.DistributionID.setValue(
      res.distributionId
    );
    this.newDistributionForm.controls.DistributionDescription.setValue(
      res.description
    );
    if (res.usages)
      this.newDistributionForm.controls.DistributionUsages.setValue(
        res.usages.split(',')
      );
    this.newDistributionForm.controls.receivedFunds.setValue(
      res.fundsReceivedAmount
    );
    // this part need fixing
    this.newDistributionForm.controls.reservationType.setValue(
      //res.reservationForUnknownRightsHoldersType
      this.reservationTypes.find(
        (x) =>
          x.value ===
          String(res.reservationForUnknownRightsHoldersType).toLowerCase()
      )
    );

    this.newDistributionForm.controls.unknownHoldersReservation.setValue(
      res.reservationForUnknownRightsHoldersValue
    );
    this.newDistributionForm.controls.isAdministrationFeesIncluded.setValue(
      res.isAdministrationFeesIncluded
    );
    if (res.isAdministrationFeesIncluded) {
      this.newDistributionForm.addControl(
        'administrationFeesAmount',
        new FormControl(0, [Validators.required])
      );
      this.newDistributionForm.addControl(
        'totalCostOfAdministrationInCurrentYear',
        new FormControl(0)
      );
      this.newDistributionForm.addControl(
        'totalCostOfAdministrationToOffset',
        new FormControl(0)
      );
      this.newDistributionForm.controls.administrationFeesAmount!.setValue(
        res.administrationFeesAmount
      );
      this.newDistributionForm.controls.totalCostOfAdministrationInCurrentYear!.setValue(
        res.totalCostOfAdministrationInCurrentYear
      );
      this.newDistributionForm.controls.totalCostOfAdministrationToOffset!.setValue(
        res.totalCostOfAdministrationToOffset
      );
    }
    this.newDistributionForm.controls.isDistributeOnlyOnRemuneratedTracksActive.setValue(
      res.isDistributeOnlyOnRemuneratedTracksActive
    );
    this.newDistributionForm.controls.includeVAT.setValue(
      res.isVATIncludedInReservation
    );
    this.newDistributionForm.controls.isUploadOnAccountActive.setValue(
      res.isUploadOnAccountActive
    );
    this.newDistributionForm.controls.isUploadWithHoldingActive.setValue(
      res.isUploadWithHoldingActive
    );
    this.newDistributionForm.controls.VATStatementType.setValue(
      res.vatStatementType || 'hide'
    );
    this.newDistributionForm.controls.royaltyType.setValue(
      res.royaltyStatementType || 'hide'
    );
    this.newDistributionForm.controls.VATStatement.setValue(res.vatStatement);
    this.newDistributionForm.controls.RecievedFundsVATAmount .setValue(res.recievedFundsVATAmount ? res.recievedFundsVATAmount: 0 );
    this.newDistributionForm
      .get('RecievedFundsVATAmount')
      ?.valueChanges.subscribe((value) => {
        if (value === null) {
          this.newDistributionForm
            .get('RecievedFundsVATAmount')
            ?.setValue(0, { emitEvent: false });
        }
      });

    this.newDistributionForm.controls.LastUsedInvoiceNumber.setValue(res.vatStatenmentLastInvoiceNumber);
    this.newDistributionForm.controls.royalty.setValue(res.royaltyStatement);
    this.newDistributionForm.controls.FirstInvoiceNumber.setValue(res.vatStatementFirstInvoiceNumber);
    this.newDistributionForm.controls.InvoiceSuffix.setValue(res.vatStatementInvoiceSuffix);
    this.data = JSON.parse(JSON.stringify(res.distributionReports));
    //this.addRow();
    this.reportsSummary.numberOfTracks = res.tracksCount;
    this.reportsSummary.labelsCount = res.labelsCount;
    this.reportsSummary.collectorsCount = res.collectorsCount;
    this.reportsSummary.unclaimedRepertoireCount = res.unclaimedRepertoireCount;
    this.reportsSummary.unclaimedRepertoireValue = res.unclaimedRepertoireValue;
    this.reportsSummary.unclaimedRepertoireStreamsDuration =
      res.unclaimedRepertoireStreamsDuration;
    this.reportsSummary.totalReportAmount = res.totalReportAmount.toFixed(2);
    this.reportsSummary.totalAmountForTransfer =
      res.totalAmountForTransfer.toFixed(2);
  }


  calculateResult(): number {
    const receivedFunds = this.newDistributionForm.get("receivedFunds")?.value || 0;
    const reservationType = this.newDistributionForm.get("reservationType")?.value?.value;
    const unknownHoldersReservation = this.newDistributionForm.get("unknownHoldersReservation")?.value || 0;
  
    const reservationValue = reservationType === "percent"
      ? (unknownHoldersReservation / 100) * receivedFunds
      : unknownHoldersReservation;
  
    return receivedFunds - reservationValue;
  }

  getOrganizationPrefix() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    const organizationId = currentUser.board.organisationId
      ? currentUser.board.organisationId
      : currentUser.board.organisation.id;

    switch (organizationId) {
      case '2195ed89-bd12-4e27-9bdf-d8fcd9b8f14d':
        this.organizationPrefix = 'dk';
        break;
      case 'b8f77630-9666-4627-b234-f4a1b5329e17':
        this.organizationPrefix = 'no';
        break;
    }
  }

  initForms() {
    this.newDistributionForm = new FormGroup<NewDistributionFormData>({
      DistributionTitle: new FormControl(null, {
        validators: [Validators.required],
      }),
      DistributionYear: new FormControl(null, {
        validators: [Validators.required, Validators.pattern('^[0-9]{4}$')],
      }),
      DistributionID: new FormControl(null, {
        validators: [Validators.required],
      }),
      DistributionDescription: new FormControl(null, {
        validators: [Validators.required],
      }),
      DistributionUsages: new FormControl([]),
      receivedFunds: new FormControl(0, {
        validators: [Validators.required, Validators.pattern('^[0-9,.]+$')],
      }),
      unknownHoldersReservation: new FormControl(),
      includeVAT: new FormControl(false),
      isAdministrationFeesIncluded: new FormControl(false),
      isDistributeOnlyOnRemuneratedTracksActive: new FormControl(false),
      reservationType: new FormControl('percent'),
      isUploadOnAccountActive: new FormControl(false),
      isUploadWithHoldingActive: new FormControl(false),
      VATStatementType: new FormControl('hide'),
      royaltyType: new FormControl('hide'),
      VATStatement: new FormControl(null),
      royalty: new FormControl(null),
      RecievedFundsVATAmount: new FormControl(0, {
        validators: [Validators.required, Validators.pattern('^[0-9,.]+$')],
        }),
        FirstInvoiceNumber : new FormControl(null,{
          validators : [Validators.required, Validators.pattern('^[0-9]+$')]
        } ),
        InvoiceSuffix : new FormControl(null),
        LastUsedInvoiceNumber : new FormControl(null,{
          validators : [Validators.pattern('^[0-9]+$')]
        }),
    });

    this.newDistributionForm.controls.royaltyType.valueChanges.subscribe(
      (value) => {
        if (value === 'customMessage') {
          this.newDistributionForm.controls.royalty.enable();
          this.newDistributionForm.controls.royalty.setValue(null);
        } else {
          if (value === 'hide') {
            this.newDistributionForm.controls.royalty.setValue(null);
          } else if (value === 'NotEligibleDist') {
            this.newDistributionForm.controls.royalty.setValue(
              'The royalties do not have to be settled, as the artists receive funds directly.'
            );
          } else if (value === 'eligibleDist') {
            this.newDistributionForm.controls.royalty.setValue(
              'Artist royalties must be settled from the remuneration, unless otherwise follows from your agreement with the artist(s).'
            );
          }
          this.newDistributionForm.controls.royalty.disable();
        }
      }
    );

    this.newDistributionForm.controls.VATStatementType.valueChanges.subscribe(
      (value) => {
        if (value === 'customMessage') {
          this.newDistributionForm.controls.VATStatement.setValue(null);
          this.newDistributionForm.controls.VATStatement.enable();
        } else {
          if (value === 'hide') {
            this.newDistributionForm.controls.VATStatement.setValue(null);
          } else if (value === 'NoVAT') {
            this.newDistributionForm.controls.VATStatement.setValue(
              'The remuneration is not subject to VAT.'
            );
          } else if (value === 'subToVAT') {
            this.newDistributionForm.controls.VATStatement.setValue(
              'The revenue is subject to VAT. You are responsible for duly reporting on this.'
            );
          }
          this.newDistributionForm.controls.VATStatement.disable();
        }
      }
    );
  }

  onIncludeAdministrationChange(event: any) {
    if (event.checked) {
      this.newDistributionForm.addControl(
        'administrationFeesAmount',
        new FormControl(0, [Validators.required])
      );
      this.newDistributionForm.addControl(
        'totalCostOfAdministrationInCurrentYear',
        new FormControl(0, [Validators.required])
      );
      this.newDistributionForm.addControl(
        'totalCostOfAdministrationToOffset',
        new FormControl(0, [Validators.required])
      );
    } else {
      this.newDistributionForm.removeControl('administrationFeesAmount');
      this.newDistributionForm.removeControl(
        'totalCostOfAdministrationInCurrentYear'
      );
      this.newDistributionForm.removeControl(
        'totalCostOfAdministrationToOffset'
      );
    }
  }

  onchange(event: DropdownChangeEvent, item: DistributionReportItem) {
    item.reporter = event.value;
    this.reportsFormChange = true;
  }

  onChageReportInputs(event: any) {
    this.reportsFormChange = true;
  }

  deleteRow(rowIndex: number, itemId: string, event: Event) {
    if(this.nonMatchedRepoortLabelsExist)
    {
      this.nonMatchedRepoortLabelsExist = false;
    }
    if (itemId) {
      this.confirmReportDeletion(event, itemId, rowIndex);
    } else {
      this.data.splice(rowIndex, 1);
    }
  }

  confirmReportDeletion(event: Event, itemId: string, rowIndex: number) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Do you want to delete this report?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-text text-light',
      // rejectButtonStyleClass: 'p-button-outlined p-button-text',
      acceptIcon: 'none',
      rejectIcon: 'none',

      accept: () => {
        this.deleteReportFile(itemId, rowIndex);
      },
      reject: () => {},
    });
  }

  addRow() {
    this.data.push({
      reporter: this.reporters[0],
    } as DistributionReportItem);
  }

  onReportUpload(event: any, item: any, FileUpload: FileUpload) {
    item.uploadLoading = true;
    if(this.nonMatchedRepoortLabelsExist)
    {
      this.nonMatchedRepoortLabelsExist = false;
    }
    if (item.id) {
      this.reportReupload(event, item, FileUpload);
      return;
    }
    // file upload
    item.file = event.files[0];

    this.distributionService
      .uploadDistributionReport({
        DistributionId: this.distributionId,
        DistributionReportName: item.name,
        DistributionReportYear: item.year,
        DistributionReportSplit: item.splitPercentage,
        ReporterId: item.reporter.Id,
        file: item.file,
      })
      .subscribe((res: any) => {
        item.uploadLoading = false;
        item.id = res.AddedDistributionReport.Id;
        item.tracks = res.AddedDistributionReport.TrackCount;
        item.assigned = res.AddedDistributionReport.AssignedCount;
        item.unassigned = res.AddedDistributionReport.UnassignedCount;
        item.streamsDuration = res.AddedDistributionReport.StreamsDurationCount;
        item.financialValue =
          res.AddedDistributionReport.FinancialValue.toFixed(2);

        this.reportsSummary.numberOfTracks = res.Distribution.TracksCount;
        this.reportsSummary.labelsCount = res.Distribution.LabelsCount;
        this.reportsSummary.collectorsCount = res.Distribution.CollectorsCount;
        this.reportsSummary.unclaimedRepertoireCount =
          res.Distribution.UnclaimedRepertoireCount;
        this.reportsSummary.unclaimedRepertoireValue =
          res.Distribution.UnclaimedRepertoireValue;
        this.reportsSummary.unclaimedRepertoireStreamsDuration =
          res.Distribution.UnclaimedRepertoireStreamsDuration;

        this.reportsSummary.totalReportAmount =
          res.Distribution.TotalReportAmount.toFixed(2);
        this.reportsSummary.totalAmountForTransfer =
          res.Distribution.TotalAmountForTransfer.toFixed(2);
      },(err: any) => {
         
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "Make Sure that there's 100% match for label names",
        });
        this.nonMatcheReportLabels = err.error.data.map(  (item:any) =>" "+ item.labelName );
        this.nonMatchedRepoortLabelsExist = true;
        
      });

    FileUpload.clear();
  }

  reportReupload(event: any, item: any, FileUpload: FileUpload) {
    
    this.distributionService
      .reuploadDistributionReport({
        DistributionId: this.distributionId,
        DistributionReportName: item.name,
        DistributionReportId: item.id,
        file: event.files[0],
      })
      .subscribe((res: any) => {
        item.uploadLoading = false;
        this.getDistribution();
      },(err: any) => {
         
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "Make Sure that there's 100% match for label names",
        });
        this.nonMatcheReportLabels = err.error.data.map((item:any) => item.labelName);
        this.nonMatchedRepoortLabelsExist = true;
        
      });
    FileUpload.clear();
  }

  deleteReportFile(reportId: string, rowIndex: number) {
    this.distributionService
      .deleteDistributionReport(this.distributionId, reportId)
      .subscribe((res: any) => {
        this.data.splice(rowIndex, 1);
        this.onRowReorder();
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmed',
          detail: 'Report deleted',
        });
        this.getDistribution();
      });
  }

  saveDistribution() {
    // Trigger slide-down animation before hiding the alert
    this.animationState = 'slide-down';
    setTimeout(() => {
      // Hide the save changes alert
      this.reportsFormChange = false;

      // how to clear  newDistributionForm  dirty
      this.newDistributionForm.markAsPristine();

      this.animationState = '';
    }, 200);

    let mapper = (item: any) => {
      return {
        distributionReportId: item.id,
        name: item.name,
        year: item.year,
        split: item.splitPercentage,
        reporterId: item.reporter.Id || item.reporter.id,
      };
    };
    let x2: any[] = this.distributionData.distributionReports.map(mapper);
    this.distributionData.tracksCount = this.reportsSummary.numberOfTracks;
    this.distributionData.labelsCount = this.reportsSummary.labelsCount;
    this.distributionData.collectorsCount = this.reportsSummary.collectorsCount;
    this.distributionData.totalAmountForTransfer =
      this.reportsSummary.totalAmountForTransfer;
    this.distributionData.totalReportAmount =
      this.reportsSummary.totalReportAmount;
    this.distributionData.unclaimedRepertoireCount =
      this.reportsSummary.unclaimedRepertoireCount;
    this.distributionData.unclaimedRepertoireValue =
      this.reportsSummary.unclaimedRepertoireValue;
    this.distributionData.unclaimedRepertoireStreamsDuration =
      this.reportsSummary.unclaimedRepertoireStreamsDuration;

    let requestBody = {
      ...this.distributionData,
      id: this.distributionId,
      title: this.newDistributionForm.value.DistributionTitle,
      description: this.newDistributionForm.value.DistributionDescription,
      distributionYear: this.newDistributionForm.value.DistributionYear,
      distributionId: this.newDistributionForm.value.DistributionID,
      usages:
        this.newDistributionForm.value.DistributionUsages?.join(',') || '',
      isAdministrationFeesIncluded:
        this.newDistributionForm.value.isAdministrationFeesIncluded,
      administrationFeesAmount:
        this.newDistributionForm.value.administrationFeesAmount ?? 0,
      totalCostOfAdministrationInCurrentYear:
        this.newDistributionForm.value.totalCostOfAdministrationInCurrentYear ?? 0,
      totalCostOfAdministrationToOffset:
        this.newDistributionForm.value.totalCostOfAdministrationToOffset,
      isDistributeOnlyOnRemuneratedTracksActive:
        this.newDistributionForm.value
          .isDistributeOnlyOnRemuneratedTracksActive,
      fundsReceivedAmount: this.newDistributionForm.value.receivedFunds,
      reservationForUnknownRightsHoldersType:
        this.newDistributionForm.value.reservationType?.value || 'percent',
      reservationForUnknownRightsHoldersValue:
        this.newDistributionForm.value.unknownHoldersReservation,
      isVATIncludedInReservation: this.newDistributionForm.value.includeVAT,
      recievedFundsVATAmount:
        this.newDistributionForm.value.RecievedFundsVATAmount,
        vatStatementFirstInvoiceNumber : this.newDistributionForm.value.FirstInvoiceNumber?.toString(),
        vatStatementInvoiceSuffix : this.newDistributionForm.value.InvoiceSuffix?.toString(),
      isUploadOnAccountActive:
        this.newDistributionForm.value.isUploadOnAccountActive,
      isUploadWithHoldingActive:
        this.newDistributionForm.value.isUploadWithHoldingActive,
      VATStatementType: this.newDistributionForm.value.VATStatementType,
      royaltyStatementType: this.newDistributionForm.value.royaltyType,
      vatStatement: this.newDistributionForm.getRawValue().VATStatement,
      royaltyStatement: this.newDistributionForm.getRawValue().royalty,
      recognizedLabelsOnAccount: this.totalOnAccount,
      recognizedLabelsWithHolding: this.totalWithHolding,
    };

    // exclude distributionReports from requestBody
    delete requestBody.distributionReports;
    console.log(requestBody);
    this.distributionService.updateDistribution(requestBody).subscribe({
      next: (res: any) => {
        let x1 = this.data.map(mapper);
        let x = x1.filter(
          (item1: any) =>
            !x2.some((item2: any) =>
              Object.keys(item1).every((key) => item1[key] === item2[key])
            )
        );
        this.distributionData.distributionReports = JSON.parse(
          JSON.stringify(this.data)
        );

        this.distributionService.updateDistributionReports(x).subscribe(() => {
          // add Success notification
          this.messageService.add({
            severity: 'success',
            summary: 'Confirmed',
            detail: 'Distribution is saved Successfully',
            life: 3000,
          });
        });
      },
      error: (err: any) => {
        // add error notification
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error while saving distribution',
          life: 3000,
        });
      },
    });
  }

  cancel() {
    // Trigger slide-down animation before hiding the alert
    this.animationState = 'slide-down';
    setTimeout(() => {
      // Hide the save changes alert
      this.reportsFormChange = false;
      this.getDistribution();
      // how to clear  newDistributionForm  dirty
      this.newDistributionForm.markAsPristine();
      this.animationState = '';
    }, 200);
  }

  onRowReorder() {
    const data = this.data.map((item, index) => {
      return {
        distributionReportId: item.id,
        reportOrder: index + 1,
      };
    });
    this.distributionService
      .reorderDistributionReports(
        data as {
          distributionReportId: string;
          reportOrder: number;
        }[]
      )
      .subscribe((res: any) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmed',
          detail: 'Reports have been reordered',
          life: 3000,
        });
      });
  }

  reservationTypes = [
    { name: 'Percent', value: 'percent' },
    { name: 'Value', value: 'value' },
  ];

  uploadWithHoldingOrOnAccount(event: any, type: string) {
    
    this.distributionService
      .uploadOnAccountOrWithHoldingSheet({
        DistributionId: this.distributionId,
        Type: type,
        file: event.files[0],
      })
      .subscribe({
        next: (res: any) => {
          this.messageService.add({
            severity: 'info',
            summary: 'Confirmed',
            detail: `${type} list uploaded`,
          });
        
          if (type === 'OnAccount') {
            if(this.nonMatchedOnAccountLabelsExist)
            {
              this.nonMatchedOnAccountLabelsExist = false;
            }
            this.totalOnAccount = res.recognizedOnAccountLabels;
          } else {
            if(this.nonMatchedWithholdingLabelsExist)
            {
              this.nonMatchedWithholdingLabelsExist = false;
            }
            this.totalWithHolding = res.recognizedWithHoldingLabels;
          }
          this.getDistribution();
        },
        error: (err: any) => {
         
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: "Make Sure that there's 100% match",
          });
          if(err.error.type == 'OnAccount')
          {
          this.nonMatchedOnAccountLabels = err.error.data.map((item: any) => " " + item.labelName);
    
          this.nonMatchedOnAccountLabelsExist = true;
          }
          else
          {
            this.nonMatchedWithholdingLabels = err.error.data.map((item : any) => " " + item.labelName);
            this.nonMatchedWithholdingLabelsExist = true;
          }
          
        },
      });
  }
 

  uploadIncomeStreams(event: any) {
    this.distributionService
      .uploadIncomeStreamsSheet(this.distributionId, event.files[0])
      .subscribe((res: any) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmed',
          detail: 'Income streams uploaded',
        });
      });
  }

  goToFinance() {
    // get labels in this distribution
    this.financeService
      .getDistributionReportLabels(this.distributionId)
      .subscribe((response: any) => {
        if (response.length > 0) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree([
              'finance/overview',
              this.distributionId,
              response[0].boardId,0
            ])
          );
          console.log(url);
          window.open(url, '_blank');
        }
      });
  }
}
