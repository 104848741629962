import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {  
  
  protected distributionServiceUrl = AppConfig.settings.apiServer.distributionServiceUrl;

 

  boardId!: string;
  constructor(private http: HttpClient) {

    this.boardId = JSON.parse(localStorage.getItem('currentUser')!).board.id;
    //this.boardId = '46BFC81A-206A-4D34-922B-4556C34B565B';
  }

  // get label tracks from distribution reports
  getLabelTracksFromDistributionReports(distributionId: string, boardId: string = this.boardId, isAggregated: number = 1) {
    return this.http.get(`${this.distributionServiceUrl}/GetLabelTracksFromDistributionReports?DistributionId=${distributionId}&BoardId=${boardId}&isAggregated=${isAggregated}`);
  }

  // get distribution details
  getDistributionDetails(distributionId: string, boardId: string = this.boardId, isAggregated: number = 1) {
    return this.http.get(`${this.distributionServiceUrl}/GetLabelDistributionDetails?DistributionId=${distributionId}&BoardId=${boardId}&isAggregated=${isAggregated}`);
  }

  // 
  // https://mf3-test-suite-distribution-service.azurewebsites.net/api/Distributions/GetFinanceListByBoardId?BoardId=46bfc81a-206a-4d34-922b-4556c34b565b&pageIndex=0&pageSize=5

  // get distribution key
  getDistributionKey(distributionId: string, boardId: string = this.boardId, isAggregated: number = 1) {
    
    return this.http.get(`${this.distributionServiceUrl}/GetLabelDistributionKey?DistributionId=${distributionId}&BoardId=${boardId}&isAggregated=${isAggregated}`);
  }

  getFinanceList(boardId: string, pageIndex: number, pageSize: number) {
    const url = `${this.distributionServiceUrl}/GetFinanceListByBoardId?BoardId=${boardId}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    return this.http.get(url);
  }

  // get Income Streams Sheet
  getIncomeStreamsSheet(distributionId: string) {
    const url = `${this.distributionServiceUrl}/GetIncomeStreamsSheet?DistributionId=${distributionId}`;
    return this.http.get(url);
  }

  // get Distribution Report Labels
  getDistributionReportLabels(distributionId: string) {
    const url = `${this.distributionServiceUrl}/GetDistributionReportLabels?DistributionId=${distributionId}`;
    return this.http.get(url);
  }

  getSubLabelFinanceList(distributionId: string,boardId: string) {
    const url = `${this.distributionServiceUrl}/GetSubLabelFinanceListByDistributionId?DistributionId=${distributionId}&BoardId=${boardId}`;
    return this.http.get(url);
  }

  downloadInvoicePdf(organizationId:string,distributionId: string, boardId: string){
    const url = `${this.distributionServiceUrl}/DownloadInvoicePdf?OrganizationId=${organizationId}&DistributionId=${distributionId}&BoardId=${boardId}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  getDistributionCorrection(distributionId: string){
    const url = `${this.distributionServiceUrl}/GetDistributionCorrection?DistributionId=${distributionId}`;
    return this.http.get(url);
  }

  exportFinanceStatement(distributionId: any, boardId: any, isAggregated: number, isParentAccountTransFerAmountBelowThreshold: number) {
    const url = `${this.distributionServiceUrl}/ExportFinanceStatementPdf?DistributionId=${distributionId}&BoardId=${boardId}&isAggregated=${isAggregated}&IsParentAccountTransFerAmountBelowThreshold=${isParentAccountTransFerAmountBelowThreshold}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  exportDistributionSummary(distributionId: any) {
    const url = `${this.distributionServiceUrl}/ExportDistributionSummary?DistributionId=${distributionId}`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
