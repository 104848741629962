import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateDecimal'
})
export class TruncateDecimalPipe implements PipeTransform {
  transform(value: number, decimalPlaces: number = 2): string {
    if (value == null) {
      return '';
    }

    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimalPlaces}})?`);
    const match = value.toString().match(regex);
    return match ? match[0] : value.toString();
  }
}