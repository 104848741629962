<div class="custom-container">
  <p-toolbar styleClass="tablePageHeader">
    <ng-template pTemplate="left">
      <h2><span class="screen-header">{{ isEditMode ? 'Edit Distribution' : 'Create New Distribution'}}</span></h2>
    </ng-template>

    <ng-template pTemplate="right">
      <button
        pButton
        type="button"
        label="Preview statements"
        class="p-button-outline"
        (click)="goToFinance()"
      ></button>
    </ng-template>
  </p-toolbar>

  <p-panel class="no-header createdistribution">
    <form [formGroup]="newDistributionForm">
      <h5 class="fw-bold text-black">Basic information</h5>

      <p class="pb-3 text-black">
        Maintain a consistent structure for titles and descriptions across all
        distributions. It is recommended to write usages as comma-separated
        text.
      </p>

      <div class="row pb-4">
        <div class="col-md-6">
          <div class="form-group">
            <label class="fw-bold" for="DistributionTitle"
              >Title of Distribution</label
            >
            <input
              placeholder="E.g.: XXXXXXX..."
              pInputText
              class="form-control"
              id="DistributionTitle"
              formControlName="DistributionTitle"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="fw-bold" for="DistributionYear"
              >Distribution Year</label
            >
            <input
              pInputText
              class="form-control"
              id="DistributionYear"
              formControlName="DistributionYear"
            />
            <small
              *ngIf="newDistributionForm.get('DistributionYear')?.invalid"
              class="text-danger"
              >Please enter a valid year</small
            >
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="fw-bold" for="DistributionID">Distribution ID</label>
            <input
              pInputText
              class="form-control"
              id="DistributionID"
              formControlName="DistributionID"
            />
          </div>
        </div>
      </div>

      <div class="row pb-4">
        <div class="col">
          <div class="form-group">
            <label class="fw-bold" for="DistributionDescription"
              >Description</label
            >
            <textarea
              pInputTextarea
              rows="5"
              class="form-control"
              id="DistributionDescription"
              formControlName="DistributionDescription"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="row pb-4">
        <div class="col">
          <div class="form-group">
            <label class="fw-bold" for="DistributionUsages">Usages</label>
            <br />
            <p-chips
              class="border-0 w-100"
              id="DistributionUsages"
              formControlName="DistributionUsages"
            />
            <br />
            <small id="username-help">
              To add a new "Usage" entry, type the word and press 'Enter' to
              save.
            </small>
          </div>
        </div>
      </div>

      <br />

      <h5 class="fw-bold text-black">Choose report(s)</h5>
      <p class="mb-0 text-black">
        The selected reports will be used for distributions. Refer to the
        following header titles to use when uploading:
      </p>
      <p class="text-black">Value = the exact value for each track</p>
      <p-table
        [value]="data"
        [columns]="cols"
        class="summary-table distribereportupload"
        (onRowReorder)="onRowReorder()"
        [tableStyle]="{
          'min-width': '85rem'
        }"
      >
        <ng-template pTemplate="header">
          <tr class="table-header" *ngIf="data.length">
            <th *ngFor="let col of cols" [width]="col.width">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-item
          let-columns="columns"
          let-index="rowIndex"
        >
          <tr [pReorderableRow]="index" #ngForm="ngForm" ngForm>
            <td class="dragreport">
              <span class="d-flex">
                <i
                  class="fa-solid fa-grip-vertical"
                  pReorderableRowHandle="index"
                ></i>
              </span>
            </td>
            <td>
              <p-dropdown
                [options]="reporters"
                (onChange)="onchange($event, item)"
                [showClear]="false"
                appendTo="body"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="d-flex gap-1" *ngIf="item.reporter">
                    <img
                      src="assets/images/{{ organizationPrefix }}/reporters/{{
                        item.reporter.name ||
                          item.reporter.Name ||
                          reporters[0].Name
                      }}.svg"
                      style="width: 30px; border-radius: 4px"
                    />
                  </div>
                </ng-template>
                <ng-template let-reporter pTemplate="item">
                  <div class="d-flex gap-1">
                    <img
                      src="assets/images/{{ organizationPrefix }}/reporters/{{
                        reporter.Name
                      }}.svg"
                      style="width: 30px; border-radius: 4px"
                    />
                  </div>
                </ng-template>
              </p-dropdown>
            </td>
            <td>
              <input
                pInputText
                class="form-control"
                [(ngModel)]="item.name"
                name="name"
                tooltipStyleClass="error-tooltip"
                [pTooltip]="'name is required.'"
                [tooltipDisabled]="ngForm.controls.name?.valid ? true : false"
                tooltipEvent="focus"
                tooltipPosition="top"
                [autoHide]="false"
                required
                (keyup)="onChageReportInputs($event)"
              />
            </td>
            <td>
              <input
                pInputText
                class="form-control"
                [(ngModel)]="item.year"
                name="year"
                tooltipStyleClass="error-tooltip"
                [pTooltip]="'Enter a valid year!'"
                tooltipEvent="focus"
                [tooltipDisabled]="ngForm.controls.year?.valid ? true : false"
                [pattern]="'[0-9]{4}'"
                tooltipPosition="top"
                [autoHide]="false"
                required
                (keyup)="onChageReportInputs($event)"
              />
            </td>
            <td>
              <input
                pInputText
                class="form-control"
                [(ngModel)]="item.splitPercentage"
                name="splitPercentage"
                tooltipStyleClass="error-tooltip"
                [pTooltip]="'Enter a valid percentage'"
                tooltipEvent="focus"
                [tooltipDisabled]="
                  ngForm.controls.splitPercentage?.valid ? true : false
                "
                [pattern]="'^\\d{1,2}(\\.\\d{3})*(,\\d{1,2})?$'"
                tooltipPosition="top"
                [autoHide]="false"
                required
                (keyup)="onChageReportInputs($event)"
              />
            </td>
            <td>
              <input
                pInputText
                class="form-control"
                [disabled]="true"
                [value]="(item.tracks | number : '' : 'es') || '-'"
              />
            </td>
            <td>
              <input
                pInputText
                class="form-control"
                [disabled]="true"
                [value]="(item.assigned | number : '' : 'es') || '-'"
              />
            </td>
            <td>
              <input
                pInputText
                class="form-control"
                [disabled]="true"
                [value]="(item.unassigned | number : '' : 'es') || '-'"
              />
            </td>
            <td>
              <input
                pInputText
                class="form-control"
                [disabled]="true"
                [value]="(item.streamsDuration | number : '' : 'es') || '-'"
              />
            </td>
            <td>
              <input
                pInputText
                class="form-control"
                [disabled]="true"
                [value]="(item.financialValue | number : '' : 'es') || '-'"
              />
            </td>
            <td>
              <p-fileUpload
                name="file"
                #ReportUpload
                [maxFileSize]="5000000"
                [pTooltip]="'You have to add Name, Year and Split before uploading'"
                [tooltipPosition]="'top'"
                [accept]="'.xlsx,.xls,.csv'"
                mode="basic"
                styleClass="p-button-outline"
                [style]="{
                  'font-size': 'smaller',
                  'min-width': '100%',
                }"
                [chooseLabel]="
                  item.uploadLoading
                    ? ''
                    : item.id
                    ? 'Reupload data'
                    : 'Upload data'
                "
                [auto]="true"
                (onSelect)="onReportUpload($event, item, ReportUpload)"
                [chooseIcon]="'pi pi-spin pi-spinner'"
                [ngClass]="item.uploadLoading ? '' : 'no-icon-upload'"
                [disabled]="!(item.name && item.year && item.splitPercentage)"
              />
            </td>
            <td>
              <span>
                <i
                  [style]="{
                    fontSize: '1rem',
                    color: 'black',
                    cursor: 'pointer'
                  }"
                  class="pi pi-times"
                  (click)="deleteRow(index, item?.id, $event)"
                ></i>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td colspan="12">
              <button
                pButton
                class="p-button-outline w-100 d-block"
                (click)="addRow()"
              >
                Add report
              </button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div class="warning-container" *ngIf="nonMatchedRepoortLabelsExist">
            <h2>Warning</h2>
            <p>
              The following reports has been recognized in the upload, but is not
              matching a name in the database:
            </p>
            <span>{{this.nonMatcheReportLabels}}</span>
          </div>
          <br />
          <div
            *ngIf="data.length"
            class="distributionsummary p-d-flex p-ai-center p-jc-between"
          >
            <p-table class="summary">
              <ng-template pTemplate="header">
                <tr>
                  <th>
                    <p>Number of tracks</p>
                  </th>
                  <th>
                    <p>Label count</p>
                  </th>
                  <th>
                    <p>Collectors count</p>
                  </th>
                  <th>
                    <p>Unclaimed repertoire</p>
                  </th>
                  <th>
                    <p>Total report amount</p>
                  </th>
                  <th>
                    <p>Total amount for transfer</p>
                  </th>
                </tr>
                <tr>
                  <td>
                    <p class="fw-bold">
                      {{ reportsSummary.numberOfTracks | number : "" : "es" }}
                    </p>
                  </td>
                  <td>
                    <p class="fw-bold">
                      {{ reportsSummary.labelsCount | number : "" : "es" }}
                    </p>
                  </td>
                  <td>
                    <p class="fw-bold">
                      {{ reportsSummary.collectorsCount | number : "" : "es" }}
                    </p>
                  </td>
                  <td>
                    <p class="fw-bold">
                      {{
                        reportsSummary.unclaimedRepertoireCount
                          | number : "" : "es"
                      }}
                    </p>
                  </td>
                  <td>
                    <p class="fw-bold">
                      {{
                        reportsSummary.totalReportAmount | number : "" : "es"
                      }}
                    </p>
                  </td>
                  <td>
                    <p class="fw-bold">
                      {{
                        reportsSummary.totalAmountForTransfer
                          | number : "" : "es"
                      }}
                    </p>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body"> </ng-template>
            </p-table>
          </div>
        </ng-template>
      </p-table>

      <p-divider type="dashed" />

      <h5 class="fw-bold mt-3 text-black">Funds for Distribution</h5>
      <p class="mb-0 text-black">
        You can add the final sum for distribution here. Uploading a list is
        optional, and it will display each line and value in the statement.
      </p>
      <div class="row py-4">
        <div class="col-md-4">
          <div class="form-group">
            <label class="fw-bold" for="receivedFunds">Received Funds</label>
            <p-inputNumber
              formControlName="receivedFunds"
              inputId="receivedFunds"
              mode="decimal"
              locale="de-DE"
              [minFractionDigits]="2"
            />
            <small
              class="text-danger"
              *ngIf="newDistributionForm.get('receivedFunds')?.invalid"
              >Numbers only are allowed.</small
            >
          </div>

        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="fw-bold" for="RecievedFundsVATAmount ">VAT Amount for received funds</label>
            <p-inputNumber
              formControlName="RecievedFundsVATAmount"
              inputId="RecievedFundsVATAmount "
              mode="decimal"
              locale="de-DE"
              [minFractionDigits]="2"
            />
            <small
              class="text-danger"
              *ngIf="newDistributionForm.get('RecievedFundsVATAmount')?.invalid"
              >Numbers only are allowed.</small
            >
          </div>
          
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="fw-bold" for="uploadList"
              >Upload income stream list (<a class="download-template-link"
                href="assets/files-template/IncomeStreams-template.xlsx"
                download
              >download template</a>)</label
            >
            <p-fileUpload
              #receivedFundsFile
              name="receivedFundsFile"
              [maxFileSize]="5000000"
              [accept]="'.xlsx,.xls,.csv'"
              mode="basic"
              [chooseLabel]="'Click to upload ...'"
              [style]="{
                'min-width': '100%',
                textAlign: 'left'
              }"
              styleClass="p-button-outline no-icon-upload"
              [auto]="true"
              (onSelect)="uploadIncomeStreams($event)"
            />
          </div>
        </div>
      </div>

      <p-divider type="dashed" />

      <h5 class="fw-bold text-black">Reservations</h5>
      <p class="mb-0 text-black">
        Reservations for unknown rights holders can be calculated as a
        percentage or a fixed value.
      </p>
      <div class="row py-4">
        <div class="form-group">
          <label class="fw-bold" for="unknownHoldersReservation"
            >Reservation for unknown rights holders</label
          >
          <div class="w-100 d-flex gap-2 align-items-center">
            <p-dropdown
              [options]="reservationTypes"
              formControlName="reservationType"
              optionLabel="name"
            />
            <p-inputNumber
              formControlName="unknownHoldersReservation"
              mode="decimal"
              locale="de-DE"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
            />
          </div>
        </div>
        <div class="col-md-3">
          <!-- <div class="d-flex flex-column justify-content-between gap-3">
            <label class="fw-bold" for="includeVAT">Include VAT</label>
            <p-inputSwitch id="includeVAT" formControlName="includeVAT" />
          </div> -->
        </div>
      </div>

      <div class="distributionsummary p-d-flex p-ai-center p-jc-between">
        <p-table class="summary">
          <ng-template pTemplate="header">
            <tr>
              <th>
                <p>Total funds for distribution</p>
              </th>
              <th>
                <p>Reservation value</p>
              </th>
              <th>
                <p>Funds to distribute ex. VAT</p>
              </th>
            </tr>
            <tr>
              <td>
                <p class="fw-bold">
                  {{
                    (newDistributionForm.get("receivedFunds")?.value
                      ? newDistributionForm.get("receivedFunds")?.value
                      : 0
                    ) | number : "1.2-2" : "es"
                  }}
                </p>
              </td>
              <td>
                <p class="fw-bold">
                  {{
                    (newDistributionForm.get("reservationType")?.value
                      ?.value === "percent"
                      ? ((newDistributionForm.get("unknownHoldersReservation")
                          ?.value || 0) /
                          100) *
                        (newDistributionForm.get("receivedFunds")?.value || 0)
                      : newDistributionForm.get("unknownHoldersReservation")
                          ?.value
                    ) | number : "1.2-2" : "es"
                  }}
                </p>
              </td>
              <td>
                <p class="fw-bold">
                  {{ calculateResult() | number : "1.2-2" : "es"}}
                    
                </p>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <p-divider type="dashed" />

      <h5 class="fw-bold text-black">VAT and Royalty statements</h5>
      <p class="mb-0 text-black">
        Labels are required to determine if the distribution should be allocated
        to other rights holders, including VAT.
      </p>
     
      <div class="row py-4">
        <div class="col-md-6">
          <h6><u>VAT statement</u></h6>
          <div class="d-flex mb-1 mt-3">
            <div class="d-flex flex-wrap gap-3">
              <div class="d-flex align-items-center">
                <p-radioButton
                  name="VATStatementType"
                  value="hide"
                  formControlName="VATStatementType"
                  inputId="hide"
                />
                <label for="hide" class="ms-2"> Hide </label>
              </div>

              <div class="d-flex align-items-center">
                <p-radioButton
                  name="VATStatementType"
                  value="NoVAT"
                  formControlName="VATStatementType"
                  inputId="NoVAT"
                />
                <label for="NoVAT" class="ms-2"> Not subject to VAT </label>
              </div>

              <div class="d-flex align-items-center">
                <p-radioButton
                  name="VATStatementType"
                  value="subToVAT"
                  formControlName="VATStatementType"
                  inputId="subToVAT"
                />
                <label for="subToVAT" class="ms-2"> Subject to VAT </label>
              </div>

              <div class="d-flex align-items-center">
                <p-radioButton
                  name="VATStatementType"
                  value="customMessage"
                  formControlName="VATStatementType"
                  inputId="customMessage"
                />
                <label for="customMessage" class="ms-2"> Custom </label>
              </div>
            </div>
          </div>
          <textarea
            rows="5"
            class="form-control"
            pInputTextarea
            formControlName="VATStatement"
            *ngIf="
              newDistributionForm.get('VATStatementType')?.value !== 'hide'
            "
          ></textarea>
        </div>
        <div class="col-md-6">
          <h6><u>Royalty</u></h6>
          <div class="d-flex">
            <div class="d-flex flex-wrap gap-3">
              <div class="flex mb-2 mt-2 align-items-center">
                <p-radioButton
                  name="royaltyType"
                  value="hide"
                  formControlName="royaltyType"
                  inputId="hide1"
                />
                <label for="hide1" class="ms-2"> Hide </label>
              </div>

              <div class="d-flex align-items-center">
                <p-radioButton
                  name="royaltyType"
                  value="NotEligibleDist"
                  formControlName="royaltyType"
                  inputId="NotEligibleDist"
                />
                <label for="NotEligibleDist" class="ms-2">
                  Not eligible for distribution
                </label>
              </div>

              <div class="d-flex align-items-center">
                <p-radioButton
                  name="royaltyType"
                  value="eligibleDist"
                  formControlName="royaltyType"
                  inputId="eligibleDist"
                />
                <label for="eligibleDist" class="ms-2">
                  Eligible for distribution
                </label>
              </div>

              <div class="d-flex align-items-center">
                <p-radioButton
                  name="royaltyType"
                  value="customMessage"
                  formControlName="royaltyType"
                  inputId="customMessage1"
                />
                <label for="customMessage1" class="ms-2"> Custom </label>
              </div>
            </div>
          </div>
          <textarea
            rows="5"
            class="form-control"
            pInputTextarea
            formControlName="royalty"
            *ngIf="newDistributionForm.get('royaltyType')?.value !== 'hide'"
          ></textarea>
        </div>
      </div>

      <p-divider type="dashed" />

      <h5 class="fw-bold text-black">VAT Statements</h5>
      <p class="mb-0 text-black">
        Set the statrting invoice number for the downloadable statements. Adding a sufix is optional
      </p>

      <div class="row py-4">
        <div class="col-md-3">
        <label class="fw-bold d-block" for="FirstInvoiceNumber">First Invoice number</label>
        <p-inputNumber  
        formControlName="FirstInvoiceNumber"
        inputId="FirstInvoiceNumber"
        [useGrouping]="false"
        class="w-100"
        ></p-inputNumber>
        </div>
        <div class="col-md-3">
        <label class="fw-bold d-block" for="InvoiceSuffix">Optional - Set suffix</label>
        <input   
        pInputText
        formControlName="InvoiceSuffix"
        inputId="InvoiceSuffix"
        class="w-100"
        >
        </div>
      </div>
      <div class="distributionsummary p-d-flex p-ai-center p-jc-between">
        <p-table  [resizableColumns]="true" [autoLayout]="true"  class="summary">
          
            <ng-template pTemplate="header">
              <tr>
              <th>
                
                <p>Last used invoice number</p>
              
              </th>
              <th>
                
               <p >Invoice Example</p>
              
              </th>
          </tr>
          <tr>
            <td>
             <p>
              {{newDistributionForm.get('LastUsedInvoiceNumber')?.value}}
             </p> 
            </td>
            <td>
              <p>
              "{{newDistributionForm.get('FirstInvoiceNumber')?.value ||'425'}}{{newDistributionForm.get('InvoiceSuffix')?.value || '-2020'}}"
            </p>
            </td>
          </tr>
          </ng-template>
  
        </p-table>
      </div>
      <p-divider type="dashed" />

      <h5 class="fw-bold mt-3 text-black">Individual collector sums</h5>
      <p class="mb-0 text-black">
        Upload data in the specified format. Both settings require a 100% match.
        Please add the data to the file or financial system and re-upload.
      </p>
      <div class="row py-4">
        <div class="col-md-6">
          <div class="d-flex flex-column justify-content-between gap-3">
            <label class="fw-bold" for="withholdings"
              >Upload Balance</label
            >
            <div class="d-flex gap-3">
              <p-inputSwitch
                id="withholdings"
                formControlName="isUploadWithHoldingActive"
              />
              <label class="fw-bold" for="withholdings">include</label>
            </div>
            <p-fileUpload
              #WithHoldingFile
              name="WithHoldingFile"
              [maxFileSize]="5000000"
              [accept]="'.xlsx,.xls,.csv'"
              mode="basic"
              [chooseLabel]="'Click to upload ...'"
              styleClass="p-button-outline no-icon-upload"
              [disabled]="
                !newDistributionForm.get('isUploadWithHoldingActive')?.value
              "
              [style]="{
                'min-width': '100%',
                textAlign: 'left'
              }"
              [auto]="true"
              (onSelect)="uploadWithHoldingOrOnAccount($event, 'WithHolding')"
            />
          <div class="warning-container" *ngIf="nonMatchedWithholdingLabelsExist">
            <h2>Warning</h2>
            <p> The following labels has been recognized in the upload, but is not matching a name in the database:</p>
            <span>{{this.nonMatchedWithholdingLabels}}</span>
            
          </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex flex-column justify-content-between gap-3">
            <label class="fw-bold" for="onAccount">Upload on-account</label>
            <div class="d-flex gap-3">
              <p-inputSwitch
                id="onAccount"
                formControlName="isUploadOnAccountActive"
              />
              <label class="fw-bold" for="onAccount">include</label>
            </div>
            <p-fileUpload
              #OnAccountFile
              name="OnAccountFile"
              [maxFileSize]="5000000"
              [accept]="'.xlsx,.xls,.csv'"
              mode="basic"
              [chooseLabel]="'Click to upload ...'"
              styleClass="p-button-outline no-icon-upload"
              [disabled]="
                !newDistributionForm.get('isUploadOnAccountActive')?.value
              "
              [style]="{
                'min-width': '100%',
                textAlign: 'left'
              }"
              [auto]="true"
              (onSelect)="uploadWithHoldingOrOnAccount($event, 'OnAccount')"
            />
            <div class="warning-container" *ngIf="nonMatchedOnAccountLabelsExist">
              <h2>Warning</h2>
              <p> The following labels has been recognized in the upload, but is not matching a name in the database:</p>
              <span>{{this.nonMatchedOnAccountLabels}}</span>
              
            </div>
          </div>
        </div>
      </div>
      <div class="distributionsummary p-d-flex p-ai-center p-jc-between">
        <p-table class="summary">
          <ng-template pTemplate="header">
            <tr>
              <th>
                <p>Recognized labels for balance</p>
              </th>
              <th>
                <p>Recognized labels for on-account</p>
              </th>
            </tr>
            <tr>
              <td>
                <p class="fw-bold">
                  {{ totalWithHolding | number : "" : "es" }} out of
                  {{ reportsSummary.labelsCount | number : "" : "es" }}

                  <span
                    *ngIf="totalWithHolding != reportsSummary.labelsCount"
                    class="red-dot"
                  ></span>
                </p>
              </td>
              <td>
                <p class="fw-bold">
                  {{ totalOnAccount | number : "" : "es" }} out of
                  {{ reportsSummary.labelsCount | number : "" : "es" }}
                </p>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <p-divider type="dashed" />

      <h5 class="fw-bold mt-3 text-black">Administration fee's</h5>
      <p class="mb-0 text-black">
        Administration fee’s will be distributed across all tracks depending of
        its share
      </p>
      <div class="d-flex gap-3 py-3">
        <p-inputSwitch
          id="isAdministrationFeesIncluded"
          formControlName="isAdministrationFeesIncluded"
          (onChange)="onIncludeAdministrationChange($event)"
        />
        <label class="fw-bold" for="isAdministrationFeesIncluded"
          >include</label
        >
      </div>
      <div
        class="row"
        *ngIf="newDistributionForm.get('isAdministrationFeesIncluded')?.value"
      >
        <div class="col-md-6">
          <div class="form-group">
            <label class="fw-bold" for="administrationFeesAmount">Amount</label>
            <p-inputNumber
              formControlName="administrationFeesAmount"
              inputId="administrationFeesAmount"
              mode="decimal"
              locale="de-DE"
              [minFractionDigits]="2"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="fw-bold" for="totalCostOfAdministrationInCurrentYear"
              >Total costs of administration in current year</label
            >
            <p-inputNumber
              formControlName="totalCostOfAdministrationInCurrentYear"
              inputId="totalCostOfAdministrationInCurrentYear"
              mode="decimal"
              locale="de-DE"
              [minFractionDigits]="2"
              
            />
            <small
              >If the field is empty or the value is zero, the line will be
              ignored.</small
            >
          </div>
        </div>
        <!-- <div class="col-md-4">
          <div class="form-group">
            <label class="fw-bold" for="totalCostOfAdministrationToOffset">Total costs of administration to
              offset</label>
            <p-inputNumber formControlName="totalCostOfAdministrationToOffset"
              inputId="totalCostOfAdministrationToOffset" mode="decimal" locale="de-DE" [minFractionDigits]="2" />
            <small>If the field is empty or the value is zero, the line will be
              ignored.</small>
          </div>
        </div> -->
      </div>
      <div class="d-flex gap-3 py-3">
        <p-inputSwitch
          id="isDistributeOnlyOnRemuneratedTracksActive"
          formControlName="isDistributeOnlyOnRemuneratedTracksActive"
          (onChange)="onIncludeAdministrationChange($event)"
        />
        <label class="fw-bold" for="isDistributeOnlyOnRemuneratedTracksActive"
          >Distribute only on remunerated tracks (ignore unclaimed)</label
        >
      </div>
      <div
        class="distributionsummary p-d-flex p-ai-center p-jc-between summary-div"
      >
        <p-table class="summary">
          <ng-template pTemplate="header">
            <tr>
              <th>
                <p>Distributed administration fees in current distribution</p>
              </th>
            </tr>
            <tr>
              <td>
                <p class="fw-bold">
                  {{
                    (newDistributionForm.get("administrationFeesAmount")?.value
                      | number : "" : "es") || 0
                  }}
                </p>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </form>
  </p-panel>
</div>

<div
  class="unsaved-changes-alert"
  [ngClass]="animationState"
  *ngIf="newDistributionForm.dirty || reportsFormChange"
>
  <p class="fw-bold mb-0">You have unsaved changes!</p>
  <button pButton class="discardbtn" (click)="cancel()">Discard changes</button>
  <button pButton class="savebtn" (click)="saveDistribution()">
    Save changes
  </button>
</div>

<p-toast />
<p-confirmDialog />
