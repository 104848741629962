import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AppConfig } from '../../../../app.config';
import { Label, PaginatedLabels } from './../models/label.model';
import { DataTableConfig } from 'src/shared/models/pagination.model';
import { CommonHelper } from 'src/core/helpers/common-helper';
import Jsona from 'jsona';

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  protected managementServiceUrl =
    AppConfig.settings.apiServer.managementServiceUrl;
  protected metafireServiceUrl = AppConfig.settings.apiServer.metafireSuiteAPI;
  protected authServiceUrl = AppConfig.settings.apiServer.authAPI;
  protected awsS3url = AppConfig.settings.apiServer.awsS3URL;

  currentUser: any = JSON.parse(localStorage.getItem('currentUser')!);
  token = this.currentUser.authenticated.access_token;
  dataFormatter = new Jsona();

  headers = new HttpHeaders({
    Authorization: 'Bearer ' + this.token, // keep it as it is
  });
  options = { headers: this.headers };

  readonly JSONContentOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private httpClient: HttpClient,
    private commonHelper: CommonHelper,
    private messageService: MessageService
  ) { }

  getLabels_old(dataTableConfig: DataTableConfig) {
    let urlQuery = this.commonHelper.composeQuery(dataTableConfig);

    return this.httpClient.get<PaginatedLabels>(
      this.managementServiceUrl + '/Label?' + urlQuery
    );
  }

  getLabels(pageIndex: string, pageSize: string, sortString: string = 'title', searchString: string = '') {
    let url =
      `${this.authServiceUrl}/boards?filter[productType]=MetafireSuite&filter.excludeRootBoard=true&page[number]=` +
      pageIndex +
      `&page[size]=` +
      pageSize +
      `&sort=` +
      sortString +
      `&filter.title=` + searchString;
    return this.httpClient.get(url, this.options).pipe(
      map((response: any) => {

        let total = response.meta.count;
        let res = this.dataFormatter.deserialize(response, {
          preferNestedDataFromData: true,
        });

        return { data: res, total: total };
      })
    );
  }

  deleteLabel(boardId: string, labelId: string): Observable<string> {
    const url = `${this.metafireServiceUrl}/api/labels/${labelId}?boardId=${boardId}`;
    return this.httpClient.delete(url).pipe(
      map(() => 'Label deleted successfully'),
      catchError(() => of('Error deleting label'))
    );
  }

  getAllLabels(pageIndex: number) {
    const url = `${this.authServiceUrl}/boards?page[number]=${pageIndex}&page[size]=1000&sort=title`;
    return this.httpClient.get<Label>(url, this.options).pipe(
      map((response: any) =>
        this.dataFormatter.deserialize(response, {
          preferNestedDataFromData: true,
        })
      )
    );
  }

  checkLabelExistence(labelName: string) {
    return this.httpClient
      .get<any>(
        this.authServiceUrl +
        `/boards?filter[productType]=MetafireSuite&filter.isActive=true&filter.title=${labelName}&page[number]=0&page[size]=999`,
        this.options
      )
      .pipe(
        map((response: any) =>
          this.dataFormatter.deserialize(response, {
            preferNestedDataFromData: true,
          })
        )
      );
  }

  imageUploadRequest(uploadedFile: any) {
    const bodyContent = new FormData();
    bodyContent.append('file', uploadedFile.files[0]);
    return this.httpClient.post(
      `${this.metafireServiceUrl}/api/upload/file`,
      bodyContent
    );
  }

  addLabel(label: Label, boardId: string) {
    const url = `${this.metafireServiceUrl}/api/labels?boardId=${boardId}`;
    const bodyContent = JSON.stringify(label);
    return this.httpClient.post(url, bodyContent, this.JSONContentOptions);
  }

  addNewLabel(label: Label, boardId: string): Observable<Label> {
    const url = `${this.metafireServiceUrl}/api/labels?boardId=${boardId}`;
    const bodyContent = JSON.stringify(label);
    return this.httpClient.post<Label>(url, bodyContent, this.JSONContentOptions).pipe(
      map((response: Label) => response) 
    );
  }

  getLabel(labelId: string) {
    return this.httpClient.get<any>(
      `${this.metafireServiceUrl}/api/labels/${labelId}`
    );
  }

  editLabel(label: Label) {
    const url = `${this.metafireServiceUrl}/api/labels/${label.id}?boardId=${label.id}`;
    const bodyContent = JSON.stringify(label);
    return this.httpClient.patch<any>(
      url,
      bodyContent,
      this.JSONContentOptions
    );
  }

  getUploadedImage(labelId: string) {
    return `${this.awsS3url}/boards/${labelId}/static/image-40x40.jpg`;
  }

  showToastMessage(severity: string, summary: string, detail: string) {
    this.messageService.add({ severity, summary, detail, key: 'toast' });
  }
}
