import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNumber'
})
export class IsNumberPipe implements PipeTransform {
  transform(value: any): boolean {
    if (typeof value === 'string') {
      // Replace comma with dot for Danish format
      value = value.replace(',', '.');
    }
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
}